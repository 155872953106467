import React, { useEffect, useState } from "react";
import {
  PagedSearchTrimming,
  CreateNewTrimming,
  UpdateTrimming,
  GetVendorCode,
  ExportDailyTrimming,
} from "../../API";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import jsPDF from "jspdf";
import { Form } from "react-bootstrap";
import { Loading } from "../../Components";
import fileDownload from "js-file-download";
import { addLeadingZero } from "../../Utility";
import QRCode from "qrcode";
import TrimmingByDate from "./TrimmingByDate";

export default function ReceivingTable() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const formattedDate = yyyy + "-" + mm + "-" + dd;
  function createTable(numRows) {
    const defaultRow = {
      trimmingDate: formattedDate,
      size: "",
      weight: "",
      sequence: 0,
      grade: "",
      remarks: "",
    };
    const table = Array.from({ length: numRows }, (row, index) => ({
      ...defaultRow,
      sequence: index + 1,
    }));
    return table;
  }

  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const [tableData, setTableData] = useState();
  const [selectedReceiving, setSelectedReceiving] = useState();
  const [vendor, setVendor] = useState();
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [date, setDate] = useState({
    start: formattedDate,
    end: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let size = tableData[index].size;
    if (name === "weight") {
      if (value < 1) {
        size = 0;
      } else if (value >= 1 && value < 2) {
        size = 1;
      } else if (value >= 2 && value < 3) {
        size = 2;
      } else {
        size = 3;
      }
    }
    const updatedTableData = [...tableData];
    updatedTableData[index] = {
      ...updatedTableData[index],
      [name]: value,
      size: size,
    };
    setTableData(updatedTableData);
  };

  useEffect(() => {
    if (keyword.length === 15) {
      setTableData();
      setSelectedReceiving();
      setIsLoading(true);
      PagedSearchTrimming(keyword)
        .then((response) => {
          const length = response.data.data.length;
          const shouldAdd =
            response.data.receiving.rawMaterialType === "CL" ||
            response.data.receiving.rawMaterialType === "DL"
              ? response.data.receiving.pcs - length
              : 4 - length;
          setTableData([...response.data.data, ...createTable(shouldAdd)]);
          setSelectedReceiving(response.data.receiving);
          setIsLoading(false);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "Trimming code not found",
            background: "danger",
          });
          setIsShowToast(true);
          setIsLoading(false);
        });
    }
  }, [keyword]);

  useEffect(() => {
    if (keyword.length === 15 || keyword.length === 14) {
      const splited = keyword.split(".");
      const [vendorCode, date, sequence] = splited;
      if (vendorCode) {
        GetVendorCode(vendorCode).then((response) => {
          setVendor(response.data);
        });
      }
    }
  }, [keyword]);

  const handleSubmit = (index) => {
    const trimming = tableData[index];
    setIsSaving(true);
    if (!trimming.uuid) {
      CreateNewTrimming({
        uuid: selectedReceiving.uuid,
        data: trimming,
      })
        .then((res) => {
          setIsSaving(false);
          setToastInfo({
            message: "Trimming successfully created",
            background: "success",
          });
          setIsShowToast(true);
          const updateData = tableData;
          updateData[index].uuid = res.data.id;
          setTableData(updateData);

          exportPDF({
            ...trimming,
            uuid: res.data.id,
            sequence: index + 1 < 10 ? `0${index + 1}` : index + 1,
          });
        })
        .catch((err) => {
          setIsSaving(false);
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "Trimming failed created",
            background: "danger",
          });
          setIsShowToast(true);
        });
    } else {
      UpdateTrimming(trimming)
        .then(() => {
          setIsSaving(false);
          setToastInfo({
            message: "Trimming successfully updated",
            background: "success",
          });
          setIsShowToast(true);
          exportPDF({
            ...trimming,
            sequence: index + 1 < 10 ? `0${index + 1}` : index + 1,
          });
        })
        .catch((err) => {
          setIsSaving(false);
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "Trimming failed updated",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  };

  const exportPDF = (trimmed) => {
    const doc = new jsPDF("l", "mm", [100, 50]);

    const lotCode = keyword + "." + addLeadingZero(trimmed.sequence, 2);

    QRCode.toDataURL(lotCode, { margin: 1 }).then((qrcode) => {
      doc.addImage(qrcode, "JPEG", 75, 2, 20, 20);

      doc.setFontSize(10);
      doc.text(`Loin no`, 5, 5);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(`${trimmed.sequence}`, 5, 10);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Internal Lot Code", 35, 5);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(15);
      doc.text(lotCode, 25, 12);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Supplier", 5, 20);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(vendor.supplierName, 5, 25);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Fleet", 5, 30);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(vendor.fleet, 5, 35);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("PDC", 5, 40);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(trimmed.trimmingDate, 5, 45);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Owner", 60, 20);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(selectedReceiving.owner, 60, 25);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Weight", 60, 30);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(`${trimmed.weight}`, 60, 35);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Certificate", 78, 30);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(vendor.certificateType, 78, 35);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Grade", 60, 40);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(trimmed.grade || selectedReceiving.grade, 60, 45);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(10);
      doc.text("Remarks", 78, 40);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(10);
      doc.text(trimmed.remarks || selectedReceiving.remarks, 78, 45);
      doc.autoPrint();
      var iframe = document.createElement("iframe");
      iframe.src = doc.output("datauristring");
      iframe.style.width = "0";
      iframe.style.height = "0";
      document.body.appendChild(iframe);
    });
  };

  const handleExportDaily = () => {
    ExportDailyTrimming(date)
      .then((response) => {
        const fileName = `trimming(${date.start} - ${date.end}).csv`;
        fileDownload(response.data, fileName);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "data not found",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Trimming</h1>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div className="col-sm-3 mt-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="input receiving code"
                      value={keyword}
                      maxLength="15"
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-sm-3 mt-3 d-flex">
                    <input
                      type="date"
                      className="form-control me-1"
                      value={date.start}
                      onChange={(e) =>
                        setDate({ ...date, start: e.target.value })
                      }
                    />
                    <input
                      type="date"
                      className="form-control me-1"
                      value={date.end}
                      onChange={(e) =>
                        setDate({ ...date, end: e.target.value })
                      }
                    />
                    <button
                      className="btn btn-primary me-1"
                      onClick={handleExportDaily}
                    >
                      Download
                    </button>
                  </div>
                </div>

                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <h5 className="card-title">{keyword}</h5>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Code</th>
                          <th scope="col">Date</th>
                          <th scope="col">Weight</th>
                          <th scope="col">Size</th>
                          <th scope="col">Grade</th>
                          <th scope="col">Remarks</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData &&
                          tableData.map((item, index) => {
                            return (
                              <tr key={index}>
                                <th>
                                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                                </th>
                                <th>
                                  <input
                                    type="date"
                                    className="form-control"
                                    name="trimmingDate"
                                    value={item.trimmingDate}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                  />
                                </th>
                                <th>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="weight"
                                    value={item.weight}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                  />
                                </th>
                                <th>
                                  <Form.Select
                                    aria-label="Select Plant"
                                    name="size"
                                    value={item.size}
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                  >
                                    <option value={null}>Select Size</option>
                                    <option value={0}>1 Down</option>
                                    <option value={1}>1 Up</option>
                                    <option value={2}>2 Up</option>
                                    <option value={3}>3 Up</option>
                                  </Form.Select>
                                </th>
                                <th>
                                  <Form.Select
                                    aria-label="Select Plant"
                                    name="grade"
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    value={
                                      item.grade || selectedReceiving.grade
                                    }
                                  >
                                    <option value={null}>Select Grade</option>
                                    <option value={"AB"}>AB</option>
                                    <option value={"ABC"}>ABC</option>
                                    <option value={"C"}>C</option>
                                    <option value={"LOCAL"}>LOCAL</option>
                                    <option value={"ASUKI"}>ASUKI</option>
                                  </Form.Select>
                                </th>
                                <th>
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    name="remarks"
                                    onChange={(e) =>
                                      handleInputChange(e, index)
                                    }
                                    value={
                                      item.remarks || selectedReceiving.remarks
                                    }
                                  />
                                </th>
                                <th>
                                  <div style={{ display: "flex" }}>
                                    <button
                                      className="form-control"
                                      onClick={() => {
                                        handleSubmit(index);
                                      }}
                                      style={{
                                        background: "unset",
                                        border: "none",
                                      }}
                                      disabled={isSaving}
                                    >
                                      {isSaving ? (
                                        <span
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                      ) : (
                                        <i className="bi bi-file-earmark-arrow-up"></i>
                                      )}
                                    </button>
                                    {/* <button
                                      className="form-control"
                                      onClick={() => {
                                        if (!item.uuid) {
                                          return;
                                        }
                                        exportPDF({
                                          ...item,
                                          sequence:
                                            index + 1 < 10
                                              ? `0${index + 1}`
                                              : index + 1,
                                        });
                                      }}
                                      style={{
                                        background: "unset",
                                        border: "none",
                                      }}
                                    >
                                      <i className="bi bi-printer"></i>
                                    </button> */}
                                  </div>
                                </th>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <TrimmingByDate />
      </section>
    </main>
  );
}
