import React, { useRef, useState } from "react";
import CSMInventory from "../../API/CSMInventory";
import { DataTable } from "../../Components";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import fileDownload from "js-file-download";
import { useModal } from "../../Hook/useModal";
import { Modal } from "react-bootstrap";

export default function Monthly({ setSelectedDetail, selectedDetail }) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const _inventory = new CSMInventory();
  const [refresh, setRefresh] = useState(false);
  const chooseFileRef = useRef();
  const { modal, toggleModal } = useModal();
  const [summarizeData, setSummarizeData] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState();

  const getInventoryReport = () => {
    _inventory.ExportIndoInventory().then((response) => {
      const fileName = `Indo-Inventory.csv`;
      fileDownload(response.data, fileName);
    });
    _inventory.ExportIndoInventoryDetail().then((response) => {
      const fileName = `Indo-Inventory-detail.csv`;
      fileDownload(response.data, fileName);
    });
  };

  const uploadFile = () => {
    _inventory
      .UploadIndoInventory(chooseFileRef.current.files[0])
      .then((response) => {
        setToastInfo({
          message: "successfully uploaded",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "failed to upload",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const tableHeader = [
    {
      name: "Vendor",
    },
    {
      name: "Pcs",
    },
    {
      name: "Weight",
    },
    {
      name: "Summarize",
    },
  ];

  const tableBody = [
    {
      name: "vendor",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedDetail(data);
            }}
          >
            {data.vendor}
          </a>
        );
      },
    },
    {
      name: "pcs",
    },
    {
      name: "weight",
    },
    {
      view: (data) => {
        return (
          <button
            className="btn btn-primary"
            onClick={() => {
              setSelectedVendor(data.vendor);
              summarize(data.vendor);
              toggleModal();
            }}
          >
            View
          </button>
        );
      },
    },
  ];

  const summarize = (vendor) => {
    _inventory
      .SummarizeIndoByVendor(vendor)
      .then((res) => setSummarizeData(res.data.data));
  };

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">Indo Loin Inventory</h5>
            <div className="d-flex gap-2">
              <input
                className="form-control"
                type="file"
                accept=".xlsx"
                ref={chooseFileRef}
              />
              <button className="btn btn-primary me-1" onClick={uploadFile}>
                Upload
              </button>
              <button
                className="btn btn-primary me-1"
                onClick={getInventoryReport}
              >
                Download
              </button>
            </div>
          </div>
          <DataTable
            api={_inventory.IndoInventoryGroup}
            pageSize={10}
            tableHeader={tableHeader}
            tableBody={tableBody}
            dependencies={[refresh]}
            activeClassName={(item) => {
              if (item?.vendor === selectedDetail?.vendor) {
                return "active";
              }
            }}
          />
        </div>
      </div>
      <Modal
        show={modal}
        onHide={() => {
          setSelectedVendor(null);
          setSummarizeData([]);
          toggleModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Summarize {selectedVendor}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table">
            <thead>
              <tr>
                <th scope="col">Grade</th>
                <th scope="col">Size</th>
                <th scope="col">Total Count</th>
                <th scope="col">Total Weight</th>
              </tr>
            </thead>
            <tbody>
              {summarizeData.length > 0 &&
                summarizeData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.grade}</td>
                      <td>{item.size}</td>
                      <td>{parseFloat(item.totalCount).toFixed(2)}</td>
                      <td>
                        <td>{parseFloat(item.totalWeight).toFixed(2)}</td>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
}
