import React, { useEffect, useState } from "react";
import { ReadReceivingByReceivingDate } from "../../API";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import Pagination from "../../Components/Pagination";
import { Loading } from "../../Components";

export default function DayTable({
  selectedDate,
  vendor,
  setDailyDate,
  setVendor,
  dailyDate,
}) {
  const { lastDataModificationTimestamp } = useApplicationStoreContext();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [receiving, setReceiving] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;

  useEffect(() => {
    setIsLoading(true);
    ReadReceivingByReceivingDate(
      pageIndex,
      pageSize,
      selectedDate.year,
      selectedDate.month,
      vendor.uuid
    ).then((response) => {
      setReceiving(response.data.data);
      setTotalCount(response.data.totalCount);
      setIsLoading(false);
    });
  }, [pageIndex, lastDataModificationTimestamp, vendor]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={`col-lg-2`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">{vendor.name}</h5>
            <div className="row align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setVendor();
                  setDailyDate();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Receiving Date</th>
              </tr>
            </thead>
            <tbody>
              {receiving &&
                receiving.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        dailyDate === item.receivingDate ? "active" : undefined
                      }
                    >
                      <th>
                        {" "}
                        <a
                          href={`view/${item.year}/${item.month}`}
                          onClick={(e) => {
                            e.preventDefault();
                            setDailyDate(item.receivingDate);
                          }}
                        >
                          {item.receivingDate}
                        </a>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalCount !== 0 && totalCount > pageSize && (
            <nav>
              <Pagination
                pageSize={pageSize}
                totalCount={totalCount}
                pageOffset={pageOffset}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPageIndex={setPageIndex}
                setPageOffset={setPageOffset}
              />
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
