import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import MonthlyTable from "./MonthlyTable";
import SupplierTable from "./SupplierTable";
import DayTable from "./DayTable";
import DetailTable from "./DetailTable";

export default function Index() {
  const [selectedDate, setSelectedDate] = useState();
  const [vendor, setVendor] = useState();
  const [dailyDate, setDailyDate] = useState();

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Receiving</h1>
      </div>
      <section className="section">
        <div className="row">
          <MonthlyTable
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
          <Outlet />
          {selectedDate && (
            <DayTable
              selectedDate={selectedDate}
              setDailyDate={setDailyDate}
              setSelectedDate={setSelectedDate}
              dailyDate={dailyDate}
            />
          )}
          {dailyDate && (
            <SupplierTable
              selectedDate={selectedDate}
              setVendor={setVendor}
              vendor={vendor}
              setSelectedDate={setSelectedDate}
              setDailyDate={setDailyDate}
              dailyDate={dailyDate}
            />
          )}
          {vendor && dailyDate && (
            <DetailTable
              selectedDate={selectedDate}
              dailyDate={dailyDate}
              setDailyDate={setDailyDate}
              vendor={vendor}
              setVendor={setVendor}
            />
          )}
        </div>
      </section>
    </main>
  );
}
