import React from "react";
import { DataTable } from "../../Components";
import CSMTrimming from "../../API/CSMTrimming";
import { intToMonth } from "../../Utility";
import { useApplicationStoreContext } from "../../Hook/UserHook";

export default function DayTable({
  selectedDate,
  setDailyDate,
  setSelectedDate,
  dailyDate,
}) {
  const { lastDataModificationTimestamp } = useApplicationStoreContext();
  const _CSMTrimming = new CSMTrimming();

  const tableHeadersCSM = [
    {
      name: "Trimming Date",
    },
  ];

  const tableBodyCSM = [
    {
      view: (data) => {
        return (
          <a
            href={`/view/${data.uuid}`}
            onClick={(e) => {
              e.preventDefault();
              setDailyDate(data.trimmingDate);
            }}
          >
            {data.trimmingDate}
          </a>
        );
      },
    },
  ];

  return (
    <div className={`col-lg-6`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">
              {intToMonth(selectedDate.month)} {selectedDate.year}
            </h5>
            <div className="row align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();

                  setDailyDate();
                  setSelectedDate();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <DataTable
            api={_CSMTrimming.ReadTrimmingByTrimmingDate}
            params={{
              year: selectedDate.year,
              month: selectedDate.month,
            }}
            tableHeader={tableHeadersCSM}
            tableBody={tableBodyCSM}
            dependencies={[selectedDate, lastDataModificationTimestamp]}
            activeClassName={(item) => {
              if (item?.trimmingDate === dailyDate) {
                return "active";
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
