import React, { useEffect, useState } from "react";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { DataTable, ConfirmationModal } from "../../Components";
import DetailForm from "./DetailForm";
import CSMReceiving from "../../API/CSMReceiving";
import { handleSizeCSMReceiving } from "../../Utility";
import { useModal } from "../../Hook/useModal";
import { Modal } from "react-bootstrap";

export default function DetailTable({
  vendor,
  dailyDate,
  setVendor,
  selectedDate,
}) {
  const _CSMReceiving = new CSMReceiving();
  const {
    setToastInfo,
    setIsShowToast,
    setLastDataModificationTimestamp,
    lastDataModificationTimestamp,
  } = useApplicationStoreContext();
  const [selectedData, setSeledtedData] = useState();
  const [summarize, setSummarize] = useState();

  const createModal = useModal();
  const confirmationModal = useModal();

  const deleteData = () => {
    _CSMReceiving
      .DeleteReceivingDetail(selectedData.uuid)
      .then(() => {
        setToastInfo({
          message: "Receiving successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setLastDataModificationTimestamp(new Date());
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response?.status === 403
              ? err.response?.data?.message
              : "Receiving failed deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const tableHeadersCSM = [
    {
      name: "RM Lot",
    },
    {
      name: "CSM Lot",
    },
    {
      name: "Type",
    },
    {
      name: "Date",
    },
    {
      name: "Vessel",
    },
    {
      name: "Origin",
    },
    {
      name: "Trip Date",
    },
    {
      name: "Status",
    },
    {
      name: "Weight",
    },
    {
      name: "Size",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyCSM = [
    {
      name: "lot",
      view: () => vendor.rmLotCode,
    },
    {
      name: "receivingLot",
    },
    {
      name: "materialType",
    },
    {
      name: "receivingDate",
    },
    {
      name: "vesselName",
    },
    {
      name: "origin",
    },
    {
      name: "tripDate",
    },
    {
      name: "fishStatus",
      view: (data) => {
        if (data.fishStatus === 0) {
          return "REJECTED";
        }
        return "GOOD";
      },
    },
    {
      name: "weight",
    },
    {
      name: "weight",
      view: (data) => {
        return handleSizeCSMReceiving(data.weight);
      },
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              data-bs-toggle="modal"
              data-bs-target="#verticalycentered"
              onClick={() => {
                setSeledtedData(data);
                createModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1"
              data-bs-toggle="modal"
              data-bs-target="#verticalycentered"
              onClick={() => {
                setSeledtedData(data);
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (vendor.uuid) {
      _CSMReceiving.SummarizeDetail(vendor.uuid).then((response) => {
        setSummarize(response.data);
      });
    }
  }, [vendor, lastDataModificationTimestamp]);

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="card-title">
                {vendor.rmLotCode}/{dailyDate}
              </h5>
            </div>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-primary me-2"
                onClick={() => {
                  setSeledtedData();
                  createModal.toggleModal();
                }}
              >
                <i className="bi bi-plus-lg"></i>
              </button>
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setVendor();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <table className="table">
                <thead>
                  <tr>
                    <th>Cateory</th>
                    <th>Total Weight</th>
                    <th>Total Count</th>
                  </tr>
                </thead>
                <tbody>
                  {summarize?.map((item, index) => {
                    return (
                      <tr className="" key={index}>
                        <td>{item.weightRange}</td>
                        <td className="mb-0">
                          {parseFloat(item.totalWeight).toFixed(2)}
                        </td>
                        <td>{item.totalCount}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <th>
                      {summarize
                        ?.reduce((acc, item) => acc + item.totalWeight, 0)
                        .toFixed(2)}
                      Kgs
                    </th>
                    <th>
                      {summarize?.reduce(
                        (acc, item) => acc + item.totalCount,
                        0
                      )}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <DataTable
            api={_CSMReceiving.ReadReceivingDetail}
            params={{
              receivingUuid: vendor.uuid,
            }}
            tableHeader={tableHeadersCSM}
            tableBody={tableBodyCSM}
            dependencies={[
              vendor,
              lastDataModificationTimestamp,
              dailyDate,
              selectedDate,
            ]}
          />
        </div>
      </div>
      <Modal
        show={createModal.modal}
        onHide={createModal.toggleModal}
        centered
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Receiving</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetailForm
            setIsOpen={createModal.toggleModal}
            CSMReceivingUuid={vendor.uuid}
            uuid={selectedData?.uuid}
            setLastDataModificationTimestamp={setLastDataModificationTimestamp}
          />
        </Modal.Body>
      </Modal>
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        onSubmit={deleteData}
        message={"Are you sure delete this item?"}
      />
    </div>
  );
}
