import React from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import DataTable from "../../Components/DataTable";
import CSMVendor from "../../API/CSMvendor";
import moment from "moment";
import { useApplicationStoreContext } from "../../Hook/UserHook";

export default function VendorTable() {
  const navigate = useNavigate();
  const _CSMVendor = new CSMVendor();
  const { lastDataModificationTimestamp } = useApplicationStoreContext();

  const tableHeadersCSM = [
    {
      name: "CSM Reff",
    },
    {
      name: "CTP Reff#",
    },
    {
      name: "Material Type",
    },
    {
      name: "Vessel Name",
    },
    {
      name: "Origin",
    },
    {
      name: "Trip Date",
    },
    {
      name: "Invoice",
    },
  ];

  const tableBodyCSM = [
    {
      name: "reff",
      view: (data) => {
        return (
          <a
            href={`/view/${data.uuid}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`view/${data.uuid}`);
            }}
          >
            {data.reff}
          </a>
        );
      },
    },
    {
      name: "rmLotCode",
    },
    {
      name: "materialType",
    },
    {
      name: "vesselName",
    },
    {
      name: "origin",
    },
    {
      name: "tripDate",
      view: (data) => {
        return data.tripDate && moment(data.tripDate).format("DD-MM-YYYY");
      },
    },
    {
      name: "invoice",
    },
  ];

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Vendor</h1>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h5 className="card-title">Vendor Table</h5>
                  <div className="row align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("add");
                      }}
                    >
                      Create New
                    </button>
                  </div>
                </div>
                <DataTable
                  api={_CSMVendor.PagedSearchVendor}
                  tableHeader={tableHeadersCSM}
                  tableBody={tableBodyCSM}
                  dependencies={[lastDataModificationTimestamp]}
                />
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </section>
    </main>
  );
}
