import { _Get, _Post, _Patch, _Delete } from "./base";
import Cookies from "js-cookie";

const endpoint = "mtm/retouching";

const PagedSearchRetouching = (keyword) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/search/${keyword}`,
    {
      ..._options,
    }
  );
};

const CreateNewRetouching = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Post(`${process.env.REACT_APP_API_URL}/${endpoint}`, data, _options);
};

const ReadRetouchingByUuid = (uuid) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
    ..._options,
  });
};

const UpdateRetouching = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Patch(`${process.env.REACT_APP_API_URL}/${endpoint}`, data, _options);
};

const DeleteRetouching = (uuid) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Delete(
    `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
    _options
  );
};

const ExportDailyRetouching = (date) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/dailyreport`, {
    params: {
      ...date,
    },
    ..._options,
  });
};

const RetouchingGroupByMonth = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/groupbydate`, {
    params: {
      ...data,
    },
    ..._options,
  });
};

const RetouchingSearchByYearandMonth = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/groupbydate/${data.year}/${data.month}`,
    {
      params: {
        ...data,
      },
      ..._options,
    }
  );
};

const RetouchingSearchByDate = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/groupbydate/${data.date}`,
    {
      params: {
        ...data,
      },
      ..._options,
    }
  );
};

const RetouchingDetail = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/groupbydate/detail/${data.date}/${data.vendorCode}`,
    {
      params: {
        ...data,
      },
      ..._options,
    }
  );
};

export {
  CreateNewRetouching,
  ReadRetouchingByUuid,
  UpdateRetouching,
  DeleteRetouching,
  PagedSearchRetouching,
  ExportDailyRetouching,
  RetouchingGroupByMonth,
  RetouchingSearchByYearandMonth,
  RetouchingSearchByDate,
  RetouchingDetail,
};
