import React, { useEffect, useState } from "react";
import { supplierSearch } from "../../API";
import Pagination from "../../Components/Pagination";
import { Loading } from "../../Components";

export default function Supplier({
  selectedDate,
  setSelectedDate,
  setSelectedDetail,
  selectedDetail,
  inventory,
}) {
  const [data, setData] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;

  const getPacking = () => {
    setIsLoading(true);
    supplierSearch(pageIndex, pageSize, selectedDate, inventory).then(
      (response) => {
        setData(response.data.data);
        setTotalCount(response.data.totalCount);
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    if (selectedDate) {
      getPacking();
    }
  }, [selectedDate, pageIndex, inventory]);

  if (!selectedDate) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">{selectedDate}</h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedDate();
                  setSelectedDetail();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">Supplier</th>
                <th scope="col">Code</th>
                <th scope="col">Total Weight (Kg)</th>
                <th scope="col">Total Pcs</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        selectedDetail?.vendorUuid === item.uuid
                          ? "active"
                          : undefined
                      }
                    >
                      <th scope="row">
                        {" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedDetail({
                              date: selectedDate,
                              vendorUuid: item.uuid,
                              vendorCode: item.vendorCode,
                            });
                          }}
                        >
                          {item.supplierName}
                        </a>
                      </th>
                      <td>{item.vendorCode}</td>
                      <td>{item.totalWeight}</td>
                      <td>{item.totalPcs}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalCount !== 0 && totalCount > pageSize && (
            <nav>
              <Pagination
                pageSize={pageSize}
                totalCount={totalCount}
                pageOffset={pageOffset}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPageIndex={setPageIndex}
                setPageOffset={setPageOffset}
              />
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
