import React, { useState } from "react";
import CuttingModel from "../../API/CSMCutting";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { DataTable } from "../../Components";
import { useModal } from "../../Hook/useModal";
import { ConfirmationModal } from "../../Components";
import fileDownload from "js-file-download";

export default function PT({
  setSelectedPT,
  selectedPT,
  setSelectedDate,
  refreshpt,
}) {
  const _cutting = new CuttingModel();
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const { modal, toggleModal } = useModal();
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);

  const handleExportDaily = (data) => {
    if (data.vendor == "ID") {
      _cutting
        .ExportCuttingPTIndo(data.uuid)
        .then((response) => {
          const fileName = `PT - ${data.pt}.csv`;
          fileDownload(response.data, fileName);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "data not found",
            background: "danger",
          });
          setIsShowToast(true);
        });
    } else if (data.vendor == "NL") {
      _cutting
        .ExportNaturalLoint(data.uuid)
        .then((response) => {
          const fileName = `PT - ${data.pt}.csv`;
          fileDownload(response.data, fileName);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "data not found",
            background: "danger",
          });
          setIsShowToast(true);
        });
    } else {
      _cutting
        .ExportCuttingPTCSM(data.uuid)
        .then((response) => {
          const fileName = `PT - ${data.pt}.csv`;
          fileDownload(response.data, fileName);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "data not found",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  };

  const handleDelete = () => {
    _cutting
      .DeleteCutting(selected.uuid)
      .then((response) => {
        setToastInfo({
          message: "PT successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "PT failed to deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const tableHeader = [
    {
      name: "PT",
    },
    {
      name: "Cut Type",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "pt",
    },
    {
      name: "vendor",
      view: (data) => {
        return data.vendor;
      },
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelectedPT({ ...data, status: "view" });
              }}
            >
              <i className="bi bi-eye"></i>
            </button>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelectedDate();
                setSelectedPT({ ...data, status: "input" });
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
            <button
              className="btn btn-info py-0 px-1 text-white"
              onClick={() => {
                handleExportDaily(data);
              }}
            >
              <i className="bi bi-download"></i>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <div className="col-lg-12s">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">PT</h5>
          <DataTable
            api={_cutting.GroupByMonth}
            tableHeader={tableHeader}
            tableBody={tableBody}
            dependencies={[refresh, refreshpt]}
            activeClassName={(item) => {
              if (item?.pt === selectedPT?.pt) {
                return "active";
              }
            }}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={modal}
        toggle={toggleModal}
        message={`Delete PT ${selected?.pt} ?`}
        onSubmit={handleDelete}
      />
    </div>
  );
}
