import React, { useState } from "react";
import moment from "moment";
import {
  RetouchingGroupByMonth,
  RetouchingSearchByYearandMonth,
  RetouchingSearchByDate,
  RetouchingDetail,
} from "../../API";
import { DataTable } from "../../Components";
import { handleSizeLBSRetouching, intToMonth } from "../../Utility";

export default function TrimmingByDate() {
  const [monthYear, setMonthYear] = useState();
  const [date, setDate] = useState();
  const [supplier, setSupplier] = useState();

  const headerMonth = [
    {
      name: "Year",
    },
    {
      name: "Month",
    },
  ];

  const bodyMonth = [
    {
      name: "reff",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setMonthYear({
                year: data.year,
                month: data.month,
              });
            }}
          >
            {data.year}
          </a>
        );
      },
    },
    {
      name: "month",
      view: (data) => intToMonth(data.month),
    },
  ];

  const headerDate = [
    {
      name: "Retouching Date",
    },
  ];

  const bodyDate = [
    {
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setDate(moment(data.retouchingDate).format("yyyy-MM-DD"));
            }}
          >
            {moment(data.retouchingDate).format("DD-MM-yyyy")}
          </a>
        );
      },
    },
  ];

  const headerVendor = [
    {
      name: "Supplier Name",
    },
    {
      name: "Vendor Code",
    },
  ];

  const bodyVendor = [
    {
      name: "supplierName",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSupplier(data.vendorCode);
            }}
          >
            {data.supplierName}
          </a>
        );
      },
    },
    {
      name: "vendorCode",
    },
  ];

  const headerDetail = [
    {
      name: "Lot Code",
    },
    {
      name: "Type",
    },
    {
      name: "Grade",
    },
    {
      name: "Weight",
    },
    {
      name: "Size",
    },

    {
      name: "Certificate",
    },
  ];

  const bodyDetail = [
    {
      name: "lotCode",
    },
    {
      name: "cuttingType",
    },
    {
      name: "grade",
    },
    {
      name: "weight",
    },
    {
      name: "size",
      view: (data) => handleSizeLBSRetouching(data.size),
    },

    {
      name: "certificate",
    },
  ];

  return (
    <>
      <div className="row">
        <div className={`${monthYear ? "col-xl-4" : "col-xl-12"}`}>
          <div className="card p-4">
            <DataTable
              api={RetouchingGroupByMonth}
              tableHeader={headerMonth}
              tableBody={bodyMonth}
              activeClassName={(item) => {
                if (
                  item?.year === monthYear?.year &&
                  item?.month === monthYear?.month
                ) {
                  return "active";
                }
              }}
            />
          </div>
        </div>
        {monthYear && (
          <div className={` ${monthYear ? "col-xl-4" : "col-xl-6"}`}>
            <div className="card p-4">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    setDate();
                    setSupplier();
                    setMonthYear();
                  }}
                >
                  X
                </button>
              </div>
              <DataTable
                api={RetouchingSearchByYearandMonth}
                tableHeader={headerDate}
                tableBody={bodyDate}
                params={{
                  year: monthYear.year,
                  month: monthYear.month,
                }}
                dependencies={[monthYear]}
                activeClassName={(item) => {
                  if (
                    moment(item.retouchingDate).format("yyyy-MM-DD") === date
                  ) {
                    return "active";
                  }
                }}
              />
            </div>
          </div>
        )}
        {date && (
          <div className={` ${date ? "col-xl-4" : "col-xl-6"}`}>
            <div className="card p-4">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    setDate();
                    setSupplier();
                  }}
                >
                  X
                </button>
              </div>
              <DataTable
                api={RetouchingSearchByDate}
                tableHeader={headerVendor}
                tableBody={bodyVendor}
                params={{
                  date,
                }}
                dependencies={[date]}
                activeClassName={(item) => {
                  if (item.vendorCode === supplier) {
                    return "active";
                  }
                }}
              />
            </div>
          </div>
        )}
        {supplier && (
          <div className="card p-4">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-danger btn-sm"
                onClick={() => setSupplier()}
              >
                X
              </button>
            </div>
            <DataTable
              api={RetouchingDetail}
              tableHeader={headerDetail}
              tableBody={bodyDetail}
              params={{
                date,
                vendorCode: supplier,
              }}
              dependencies={[supplier]}
            />
          </div>
        )}
      </div>
    </>
  );
}
