import React, { useState } from "react";
import PDC from "./PDC";
import CsIndo from "./CsIndo";
import Cs from "./Cs";
import { useParams } from "react-router-dom";

export default function Index() {
  const { inventory } = useParams();

  const renderSelection = () => {
    switch (inventory) {
      case "indo":
        return <CsIndo />;
      case "cs":
        return <Cs inventory={inventory} />;
      default:
        return <PDC inventory={inventory} />;
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Inventory {inventory}</h1>
      </div>
      <section className="section">
        <div className="row">{renderSelection()}</div>
      </section>
    </main>
  );
}
