import React, { useEffect, useState } from "react";
import CSMPacking2 from "../../API/CSMPacking2";
import CSMCutting from "../../API/CSMCutting";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Input } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Action } from "../../Constant";
import moment from "moment";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { Modal } from "react-bootstrap";

export default function ModalCreate({ show, toggleModal, setRefresh, uuid }) {
  const { setToastInfo, setIsShowToast, setLastDataModificationTimestamp } =
    useApplicationStoreContext();
  const _csmpacking = new CSMPacking2();
  const _csmcutting = new CSMCutting();
  const [action, setAction] = useState(Action.CREATE);
  const [totalCount, setTotalCount] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      packingDate: moment(new Date()).format("YYYY-MM-DD"),
      lot: "",
      pt: "",
      pdc: moment(new Date()).format("YYYY-MM-DD"),
      source: "",
      code: "",
      sap: "",
      qty: "",
      batch: "",
      grade: "",
      type: "",
      superBatch: "",
      repack: [],
    },
    validationSchema: Yup.object({
      packingDate: Yup.string().required("receiving date is required"),
      lot: Yup.string().required("lot is required"),
      type: Yup.object().required("Type is required"),
      pt: Yup.object().required("PT is required"),
      pdc: Yup.string().required("PDC is required"),
      code: Yup.object().required("Code is required"),
      sap: Yup.object().required("SAP is required"),
      qty: Yup.number().required("Qty is required"),
      batch: Yup.string().required("Batch is required"),
    }),
    onSubmit: (values) => {
      // const newRepack = values.repack.map((item) => {
      //   return { ...item, packing2Uuid: uuid };
      // });

      const data = {
        lot: values.lot,
        packingDate: values.packingDate,
        cuttingUuid: values.pt.value,
        type: values.type.value,
        source: values.source,
        pdc: values.pdc,
        code: values.code.value,
        sap: values.sap.value,
        qty: values.qty,
        batch: values.batch,
        grade: values.grade.value,
        superBatch: values.superBatch,
        weight: values.sap.value.includes("107") ? values.weight : null,
      };

      if (action === Action.CREATE) {
        _csmpacking
          .CreateNewPacking(data)
          .then((response) => {
            setToastInfo({
              message: "Packing successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date());
            setTotalCount(response.data.count);
            exportPdf(
              values.type.value === "A"
                ? values.lot +
                    moment(values.pdc).format("DDMMYY") +
                    values.code.value
                : values.lot + values.code.value,
              values.sap.value,
              values.batch,
              values.type.value,
              values.superBatch
            );
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Packing failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        _csmpacking
          .UpdatePacking(uuid, data)
          .then((response) => {
            setToastInfo({
              message: "Packing successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            toggleModal();
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Packing failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  const exportPdf = (lot, sap, batch, type, superBatch) => {
    var doc = new jsPDF("p", "mm", [50, 50]);

    QRCode.toDataURL(`${lot}\t${sap}\t${batch}\t${type}\t${superBatch}`, {
      margin: 1,
    }).then((lotCode) => {
      doc.addImage(lotCode, "JPEG", 5, 33, 14, 14);
      doc.setFontSize(11);
      doc.text(lot, 12, 30, null, 90);

      doc.addImage(lotCode, "JPEG", 32, 33, 14, 14);
      doc.setFontSize(11);
      doc.text(lot, 40, 30, null, 90);
      doc.autoPrint();
      var iframe = document.createElement("iframe");
      iframe.src = doc.output("datauristring");
      iframe.style.width = "0";
      iframe.style.height = "0";
      document.body.appendChild(iframe);
    });
  };

  useEffect(() => {
    if (uuid) {
      setAction(Action.EDIT);
      _csmpacking.PackingDetail(uuid).then((response) => {
        const update = {
          ...response.data,
          type: {
            value: response.data.type,
            label: response.data.type === "A" ? "Indo Loin" : "Thai Loin",
          },
          packingDate: moment(response.data.packingDate).format("YYYY-MM-DD"),
          grade: { value: response.data.grade, label: response.data.grade },
          sap: { value: response.data.sap, label: response.data.sap },
          code: { value: response.data.code, label: response.data.code },
          pdc: moment(response.data.pdc).format("YYYY-MM-DD"),
          pt: { value: response.data.cuttingUuid, label: response.data.pt },
        };
        formik.setValues(update);
      });
    }
  }, [uuid]);

  return (
    <Modal show={show} onHide={toggleModal} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create Packing</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          />
        </div>
        <div className="modal-body">
          <form>
            {totalCount}
            <Input
              label="Date"
              type="date"
              name="packingDate"
              onChange={formik.handleChange}
              value={formik.values.packingDate}
              errorMessage={formik.errors?.packingDate}
              isError={formik.errors.packingDate && formik.touched.packingDate}
            />
            <Input
              label="Type"
              type="select"
              name="type"
              onChange={(e) => {
                if (formik.values.lot) {
                  formik.setFieldValue("lot", "");
                }

                formik.setFieldValue("type", e.target.value);
              }}
              value={formik.values.type}
              placeholder="Select Type"
              errorMessage={formik.errors?.type}
              isError={formik.errors.type && formik.touched.type}
              options={[
                { value: "A", label: "Indo Loin" },
                { value: "B", label: "Thai Loin" },
              ]}
            />
            <Input
              label="Lot"
              type="text"
              name="lot"
              onChange={formik.handleChange}
              value={formik.values.lot}
              placeholder="Input LOT"
              errorMessage={formik.errors?.lot}
              isError={formik.errors.lot && formik.touched.lot}
            />
            {formik.values?.type?.value === "A" && (
              <Input
                label="Source"
                type="text"
                name="source"
                onChange={formik.handleChange}
                value={formik.values.source}
                placeholder="Input Source"
                errorMessage={formik.errors?.source}
                isError={formik.errors.source && formik.touched.source}
              />
            )}
            <Input
              label="PT"
              type="select-api"
              name="pt"
              onChange={formik.handleChange}
              value={formik.values.pt}
              placeholder="Select PT"
              errorMessage={formik.errors?.pt}
              isError={formik.errors.pt && formik.touched.pt}
              api={_csmcutting.GroupByMonth}
              handleSetOptions={(pt) => ({ value: pt.uuid, label: pt.pt })}
            />
            <Input
              label="PDC Line2"
              type="date"
              name="pdc"
              onChange={formik.handleChange}
              value={formik.values.pdc}
              errorMessage={formik.errors?.pdc}
              isError={formik.errors.pdc && formik.touched.pdc}
            />
            <Input
              label="Code"
              type="select"
              name="code"
              onChange={formik.handleChange}
              value={formik.values.code}
              placeholder="Select Code"
              errorMessage={formik.errors?.code}
              isError={formik.errors.code && formik.touched.code}
              options={[
                { value: "A", label: "06.00 - 08.00 (A)" },
                { value: "B", label: "08.00 - 10.00 (B)" },
                { value: "C", label: "10.00 - 12.00 (C)" },
                { value: "D", label: "12.00 - 14.00 (D)" },
                { value: "E", label: "14.00 - 16.00 (E)" },
                { value: "F", label: "16.00 - 18.00 (F)" },
                { value: "G", label: "18.00 - 20.00 (G)" },
                { value: "H", label: "20.00 - 22.00 (H)" },
                { value: "I", label: "22.00 - 24.00 (I)" },
              ]}
            />
            <Input
              label="SAP"
              type="select-api"
              name="sap"
              onChange={formik.handleChange}
              value={formik.values.sap}
              placeholder="Select SAP"
              errorMessage={formik.errors?.sap}
              isError={formik.errors.sap && formik.touched.sap}
              api={_csmpacking.GetSAPList}
              handleSetOptions={(pt) => ({ value: pt.code, label: pt.code })}
            />
            <Input
              label="Qty"
              type="number"
              name="qty"
              onChange={formik.handleChange}
              value={formik.values.qty}
              placeholder="Input Qty"
              errorMessage={formik.errors?.qty}
              isError={formik.errors.qty && formik.touched.qty}
            />
            {formik.values?.sap?.value?.includes("107") && (
              <Input
                label="Weight"
                type="number"
                name="weight"
                onChange={formik.handleChange}
                value={formik.values.weight}
                placeholder="Input Weight"
                errorMessage={formik.errors?.weight}
                isError={formik.errors.weight && formik.touched.weight}
              />
            )}
            <Input
              label="Batch"
              type="text"
              name="batch"
              onChange={formik.handleChange}
              value={formik.values.batch}
              placeholder="Input Batch"
              errorMessage={formik.errors?.batch}
              isError={formik.errors.batch && formik.touched.batch}
            />
            <Input
              label="Grade"
              type="select"
              name="grade"
              onChange={formik.handleChange}
              value={formik.values.grade}
              placeholder="Select Grade"
              errorMessage={formik.errors?.grade}
              isError={formik.errors.grade && formik.touched.grade}
              options={[
                { value: "A", label: "A" },
                { value: "AA", label: "AA" },
                { value: "AAA", label: "AAA" },
                { value: "B", label: "B" },
                { value: "AJ", label: "AJ" },
                { value: "A1", label: "A1" },
                { value: "A2", label: "A2" },
              ]}
            />
            <Input
              label="Super Batch"
              type="text"
              name="superBatch"
              onChange={formik.handleChange}
              value={formik.values.superBatch}
              placeholder="Input Super Batch"
              errorMessage={formik.errors?.superBatch}
              isError={formik.errors.superBatch && formik.touched.superBatch}
            />
            {/* {uuid && (
              <div className="mb-2">
                <hr />
                <h6>Repack</h6>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Qty</th>
                      <th>Batch</th>
                      <th>Super Batch</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.repack.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Input
                              useLabel={false}
                              label="SAP"
                              type="select-api"
                              name="sap"
                              onChange={(e) => {
                                const updatedRepack = [...formik.values.repack];
                                updatedRepack[index] = {
                                  ...updatedRepack[index],
                                  sap: e.target.value.value,
                                };

                                formik.setFieldValue("repack", updatedRepack);
                              }}
                              value={{ value: item.sap, label: item.sap }}
                              placeholder="Select SAP"
                              api={_csmpacking.GetSAPList}
                              handleSetOptions={(pt) => ({
                                value: pt.code,
                                label: pt.code,
                              })}
                              containerClassName="-"
                            />
                          </td>
                          <td>
                            <Input
                              useLabel={false}
                              type="number"
                              name="qty"
                              onChange={(e) => {
                                const updatedRepack = [...formik.values.repack];
                                updatedRepack[index] = {
                                  ...updatedRepack[index],
                                  qty: e.target.value,
                                };

                                formik.setFieldValue("repack", updatedRepack);
                              }}
                              value={item.qty}
                              placeholder="Input Qty"
                              containerClassName="-"
                            />
                          </td>
                          <td>
                            <Input
                              useLabel={false}
                              type="text"
                              name="batch"
                              onChange={(e) => {
                                const updatedRepack = [...formik.values.repack];
                                updatedRepack[index] = {
                                  ...updatedRepack[index],
                                  batch: e.target.value,
                                };

                                formik.setFieldValue("repack", updatedRepack);
                              }}
                              value={item.batch}
                              placeholder="Input Batch"
                              containerClassName="-"
                            />
                          </td>
                          <td>
                            <Input
                              useLabel={false}
                              type="text"
                              name="superBatch"
                              onChange={(e) => {
                                const updatedRepack = [...formik.values.repack];
                                updatedRepack[index] = {
                                  ...updatedRepack[index],
                                  superBatch: e.target.value,
                                };

                                formik.setFieldValue("repack", updatedRepack);
                              }}
                              value={item.superBatch}
                              placeholder="Input Super Batch"
                              containerClassName="-"
                            />
                          </td>
                          <td>
                            <button
                              className="btn btn-danger"
                              onClick={(e) => {
                                e.preventDefault();
                                const undeleted = formik.values.repack.filter(
                                  (data, i) => i != index
                                );

                                formik.setFieldValue("repack", undeleted);
                              }}
                            >
                              <i className="bi bi-trash3"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <button
                  className="w-100 btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.setFieldValue("repack", [
                      ...formik.values.repack,
                      {
                        sap: "",
                        qty: "",
                        batch: "",
                        superBatch: "",
                      },
                    ]);
                  }}
                >
                  +
                </button>
              </div>
            )} */}
            <div className="row mb-3">
              <div className=" d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal();
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="ms-2 btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  {action === Action.CREATE ? "Create" : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
