import React, { useState } from "react";
import { debounce } from "lodash";
import fileDownload from "js-file-download";
import CSMInventory from "../../API/CSMInventory";
import { DataTable } from "../../Components";

export default function PDC({ inventory }) {
  const _inventory = new CSMInventory();

  const getInventoryReport = () => {
    _inventory.ExportInventoryDaily(inventory).then((response) => {
      const fileName = `packing(${inventory}).csv`;
      fileDownload(response.data, fileName);
    });
  };

  const [data, setData] = useState([]);

  const header = [
    {
      name: "Lot",
    },
    {
      name: "PDC",
    },
    {
      name: "Total Weight (Kg)",
    },
    {
      name: "Total Count",
    },
  ];

  const tableBody = [
    {
      name: "lot",
    },
    {
      name: "pdc",
    },
    {
      name: "totalWeight",
      view: (data) => {
        return parseFloat(data.totalWeight).toFixed(2);
      },
    },
    {
      name: "totalCount",
    },
  ];

  const handleDebouncedChange = debounce((value) => {
    if (value) {
      _inventory
        .GetSearchByLot(inventory, value)
        .then((response) => setData(response.data.data));
    } else {
      setData([]);
    }
  }, 500);

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">PDC</h5>
            <button
              className="btn btn-primary me-1"
              onClick={getInventoryReport}
            >
              Download
            </button>
          </div>
          <DataTable
            api={_inventory.GetPDC}
            params={{ inventory }}
            tableHeader={header}
            tableBody={tableBody}
            dependencies={[inventory]}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="">
            <h5 className="card-title">History</h5>
          </div>
          <div className="row">
            <div className="col-xl-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search By Lot"
                onChange={(e) => handleDebouncedChange(e.target.value)}
              />
            </div>
          </div>
          <DataTable tableHeader={header} tableBody={tableBody} data={data} />
        </div>
      </div>
    </div>
  );
}
