import React, { useEffect, useState } from "react";
import { pdcSearch, inventoryReport } from "../../API";
import Pagination from "../../Components/Pagination";
import Loading from "../../Components/Loading";
import { intToMonth } from "../../Utility";
import fileDownload from "js-file-download";

export default function Monthly({
  selectedDate,
  setSelectedDate,
  setSelectedDetail,
  inventory,
}) {
  const [monthly, setMonthly] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;

  useEffect(() => {
    setIsLoading(true);
    pdcSearch(pageIndex, pageSize, inventory).then((response) => {
      setMonthly(response.data.data);
      setTotalCount(response.data.totalCount);
      setIsLoading(false);
    });
  }, [pageIndex, inventory]);

  const getInventoryReport = () => {
    inventoryReport(inventory).then((response) => {
      const fileName = `packing(${inventory}).csv`;
      fileDownload(response.data, fileName);
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={`col-lg-${selectedDate ? "6" : "12"}`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">PDC</h5>
            <button
              className="btn btn-primary me-1"
              onClick={getInventoryReport}
            >
              Download
            </button>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Year</th>
                <th scope="col">Month</th>
                <th scope="col">Total Box</th>
              </tr>
            </thead>
            <tbody>
              {monthly &&
                monthly.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        selectedDate?.year === item.year &&
                        selectedDate?.month === item.month
                          ? "active"
                          : undefined
                      }
                    >
                      <th scope="row">
                        {" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedDate({
                              year: item.year,
                              month: item.month,
                            });
                            setSelectedDetail();
                          }}
                        >
                          {item.year}
                        </a>
                      </th>
                      <th>{intToMonth(item.month)}</th>
                      <th>{item.count}</th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalCount !== 0 && totalCount > pageSize && (
            <nav>
              <Pagination
                pageSize={pageSize}
                totalCount={totalCount}
                pageOffset={pageOffset}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPageIndex={setPageIndex}
                setPageOffset={setPageOffset}
              />
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
