import React, { useEffect, useState } from "react";
import CSMRepack from "../../API/CSMRepack";
import CSMPacking2 from "../../API/CSMPacking2";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Input } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Action } from "../../Constant";
import moment from "moment";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { Modal } from "react-bootstrap";

export default function ModalCreate({ show, toggleModal, setRefresh, uuid }) {
  const { setToastInfo, setIsShowToast, setLastDataModificationTimestamp } =
    useApplicationStoreContext();
  const _csmrepack = new CSMRepack();
  const _csmpacking = new CSMPacking2();
  const [action, setAction] = useState(Action.CREATE);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      repackDate: moment(new Date()).format("YYYY-MM-DD"),
      sourceItem: "",
      sourceQty: "",
      sourceBatch: "",
      sourceSuperBatch: "",
      sourceLot: "",
      sourcePdc2: "",
      sourceCode: "",
      destinationItem: "",
      destinationQty: "",
      destinationBatch: "",
      destinationSuperBatch: "",
      destinationLot: "",
      destinationPdc2: "",
      destinationCode: "",
    },
    validationSchema: Yup.object({
      repackDate: Yup.string().required("repack date is required"),
      sourceItem: Yup.object().required("SAP is required"),
      sourceQty: Yup.number().required("Qty is required"),
      sourceBatch: Yup.string().required("Batch is required"),
      sourceSuperBatch: Yup.string().required("Super Batch is required"),
      sourceLot: Yup.string().required("Lot is required"),
      sourcePdc2: Yup.date().required("Lot is required"),
      sourceCode: Yup.object().required("Code is required"),
      destinationItem: Yup.object().required("SAP is required"),
      destinationQty: Yup.number().required("Qty is required"),
      destinationBatch: Yup.string().required("Batch is required"),
      destinationSuperBatch: Yup.string().required("Super Batch is required"),
      destinationLot: Yup.string().required("Lot is required"),
      destinationPdc2: Yup.date().required("Lot is required"),
      destinationCode: Yup.object().required("Code is required"),
    }),
    onSubmit: (values) => {
      const data = {
        repackDate: values.repackDate,
        sourceItem: values.sourceItem.value,
        sourceQty: values.sourceQty,
        sourceBatch: values.sourceBatch,
        sourceSuperBatch: values.sourceSuperBatch,
        sourceLot: values.sourceLot,
        sourcePdc2: values.sourcePdc2,
        sourceCode: values.sourceCode.value,
        sourceWeight: values.sourceWeight,
        destinationItem: values.destinationItem.value,
        destinationQty: values.destinationQty,
        destinationBatch: values.destinationBatch,
        destinationSuperBatch: values.destinationSuperBatch,
        destinationLot: values.destinationLot,
        destinationPdc2: values.destinationPdc2,
        destinationCode: values.destinationCode.value,
        destinationWeight: values.destinationWeight,
      };

      if (action === Action.CREATE) {
        _csmrepack
          .CreateRepack(data)
          .then((response) => {
            setToastInfo({
              message: "Repack successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date());

            exportPdf(
              data.destinationLot + data.destinationCode,
              data.destinationItem,
              data.destinationBatch,
              parseInt(data.destinationLot[0]) ? "B" : "A",
              data.destinationSuperBatch
            );
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Packing failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        _csmrepack
          .UpdateRepack(uuid, data)
          .then((response) => {
            setToastInfo({
              message: "Packing successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            toggleModal();
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Packing failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  const exportPdf = (lot, sap, batch, type, superBatch) => {
    var doc = new jsPDF("p", "mm", [50, 50]);

    QRCode.toDataURL(`${lot}\t${sap}\t${batch}\t${type}\t${superBatch}`, {
      margin: 1,
    }).then((lotCode) => {
      doc.addImage(lotCode, "JPEG", 5, 33, 14, 14);
      doc.setFontSize(11);
      doc.text(lot, 12, 30, null, 90);

      doc.addImage(lotCode, "JPEG", 32, 33, 14, 14);
      doc.setFontSize(11);
      doc.text(lot, 40, 30, null, 90);
      doc.autoPrint();
      var iframe = document.createElement("iframe");
      iframe.src = doc.output("datauristring");
      iframe.style.width = "0";
      iframe.style.height = "0";
      document.body.appendChild(iframe);
    });
  };

  useEffect(() => {
    if (uuid) {
      setAction(Action.EDIT);
      _csmrepack.ReadRepack(uuid).then((response) => {
        const update = {
          ...response.data,
          repackDate: moment(response.data.repackDate).format("YYYY-MM-DD"),
          sourceItem: {
            value: response.data.sourceItem,
            label: response.data.sourceItem,
          },
          destinationItem: {
            value: response.data.destinationItem,
            label: response.data.destinationItem,
          },
          sourceCode: {
            value: response.data.code,
            label: response.data.code,
          },
          sourceCode: {
            value: response.data.sourceCode,
            label: response.data.sourceCode,
          },
          destinationCode: {
            value: response.data.destinationCode,
            label: response.data.destinationCode,
          },
          code: { value: response.data.code, label: response.data.code },
        };
        formik.setValues(update);
      });
    }
  }, [uuid]);

  return (
    <Modal show={show} onHide={toggleModal} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create Repack</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          />
        </div>
        <div className="modal-body">
          <form>
            <Input
              label="Date"
              type="date"
              name="repackDate"
              onChange={formik.handleChange}
              value={formik.values.repackDate}
              errorMessage={formik.errors?.repackDate}
              isError={formik.errors.repackDate && formik.touched.repackDate}
            />
            <hr />
            <h6>Before Repack</h6>
            <Input
              label="SAP"
              type="select-api"
              name="sourceItem"
              onChange={formik.handleChange}
              value={formik.values.sourceItem}
              placeholder="Select Item"
              errorMessage={formik.errors?.sourceItem}
              isError={formik.errors.sourceItem && formik.touched.sourceItem}
              api={_csmpacking.GetSAPList}
              handleSetOptions={(pt) => ({ value: pt.code, label: pt.code })}
            />
            <Input
              label="Qty"
              type="number"
              name="sourceQty"
              onChange={formik.handleChange}
              value={formik.values.sourceQty}
              placeholder="Input Qty"
              errorMessage={formik.errors?.sourceQty}
              isError={formik.errors.sourceQty && formik.touched.sourceQty}
            />
            {formik.values?.sourceItem?.value?.includes("107") && (
              <Input
                label="Weight"
                type="number"
                name="sourceWeight"
                onChange={formik.handleChange}
                value={formik.values.sourceWeight}
                placeholder="Input Weight"
                errorMessage={formik.errors?.sourceWeight}
                isError={
                  formik.errors.sourceWeight && formik.touched.sourceWeight
                }
              />
            )}
            <Input
              label="Batch"
              type="text"
              name="sourceBatch"
              onChange={formik.handleChange}
              value={formik.values.sourceBatch}
              placeholder="Input Batch"
              errorMessage={formik.errors?.sourceBatch}
              isError={formik.errors.sourceBatch && formik.touched.sourceBatch}
            />
            <Input
              label="Super Batch"
              type="text"
              name="sourceSuperBatch"
              onChange={formik.handleChange}
              value={formik.values.sourceSuperBatch}
              placeholder="Input Super Batch"
              errorMessage={formik.errors?.sourceSuperBatch}
              isError={
                formik.errors.sourceSuperBatch &&
                formik.touched.sourceSuperBatch
              }
            />
            <Input
              label="Lot"
              type="text"
              name="sourceLot"
              onChange={formik.handleChange}
              value={formik.values.sourceLot}
              placeholder="Input Lot"
              errorMessage={formik.errors?.sourceLot}
              isError={formik.errors.sourceLot && formik.touched.sourceLot}
            />
            <Input
              label="Code"
              type="select"
              name="sourceCode"
              onChange={formik.handleChange}
              value={formik.values.sourceCode}
              placeholder="Select Code"
              errorMessage={formik.errors?.sourceCode}
              isError={formik.errors.sourceCode && formik.touched.sourceCode}
              options={[
                { value: "A", label: "06.00 - 08.00 (A)" },
                { value: "B", label: "08.00 - 10.00 (B)" },
                { value: "C", label: "10.00 - 12.00 (C)" },
                { value: "D", label: "12.00 - 14.00 (D)" },
                { value: "E", label: "14.00 - 16.00 (E)" },
                { value: "F", label: "16.00 - 18.00 (F)" },
                { value: "G", label: "18.00 - 20.00 (G)" },
                { value: "H", label: "20.00 - 22.00 (H)" },
                { value: "I", label: "22.00 - 24.00 (I)" },
              ]}
            />
            <Input
              label="PDC Line 2"
              type="date"
              name="sourcePdc2"
              onChange={formik.handleChange}
              value={formik.values.sourcePdc2}
              errorMessage={formik.errors?.sourcePdc2}
              isError={formik.errors.sourcePdc2 && formik.touched.sourcePdc2}
            />
            <hr />
            <h6>After Repack</h6>
            <Input
              label="SAP"
              type="select-api"
              name="destinationItem"
              onChange={formik.handleChange}
              value={formik.values.destinationItem}
              placeholder="Select Item"
              errorMessage={formik.errors?.destinationItem}
              isError={
                formik.errors.destinationItem && formik.touched.destinationItem
              }
              api={_csmpacking.GetSAPList}
              handleSetOptions={(pt) => ({ value: pt.code, label: pt.code })}
            />
            <Input
              label="Qty"
              type="number"
              name="destinationQty"
              onChange={formik.handleChange}
              value={formik.values.destinationQty}
              placeholder="Input Qty"
              errorMessage={formik.errors?.destinationQty}
              isError={
                formik.errors.destinationQty && formik.touched.destinationQty
              }
            />
            {formik.values?.destinationItem?.value?.includes("107") && (
              <Input
                label="Weight"
                type="number"
                name="destinationWeight"
                onChange={formik.handleChange}
                value={formik.values.destinationWeight}
                placeholder="Input Weight"
                errorMessage={formik.errors?.destinationWeight}
                isError={
                  formik.errors.destinationWeight &&
                  formik.touched.destinationWeight
                }
              />
            )}
            <Input
              label="Batch"
              type="text"
              name="destinationBatch"
              onChange={formik.handleChange}
              value={formik.values.destinationBatch}
              placeholder="Input Batch"
              errorMessage={formik.errors?.destinationBatch}
              isError={
                formik.errors.destinationBatch &&
                formik.touched.destinationBatch
              }
            />
            <Input
              label="Super Batch"
              type="text"
              name="destinationSuperBatch"
              onChange={formik.handleChange}
              value={formik.values.destinationSuperBatch}
              placeholder="Input Super Batch"
              errorMessage={formik.errors?.destinationSuperBatch}
              isError={
                formik.errors.destinationSuperBatch &&
                formik.touched.destinationSuperBatch
              }
            />
            <Input
              label="Lot"
              type="text"
              name="destinationLot"
              onChange={formik.handleChange}
              value={formik.values.destinationLot}
              placeholder="Input Lot"
              errorMessage={formik.errors?.destinationLot}
              isError={
                formik.errors.destinationLot && formik.touched.destinationLot
              }
            />
            <Input
              label="Code"
              type="select"
              name="destinationCode"
              onChange={formik.handleChange}
              value={formik.values.destinationCode}
              placeholder="Select Code"
              errorMessage={formik.errors?.destinationCode}
              isError={
                formik.errors.destinationCode && formik.touched.destinationCode
              }
              options={[
                { value: "A", label: "06.00 - 08.00 (A)" },
                { value: "B", label: "08.00 - 10.00 (B)" },
                { value: "C", label: "10.00 - 12.00 (C)" },
                { value: "D", label: "12.00 - 14.00 (D)" },
                { value: "E", label: "14.00 - 16.00 (E)" },
                { value: "F", label: "16.00 - 18.00 (F)" },
                { value: "G", label: "18.00 - 20.00 (G)" },
                { value: "H", label: "20.00 - 22.00 (H)" },
                { value: "I", label: "22.00 - 24.00 (I)" },
              ]}
            />
            <Input
              label="PDC Line 2"
              type="date"
              name="destinationPdc2"
              onChange={formik.handleChange}
              value={formik.values.destinationPdc2}
              errorMessage={formik.errors?.destinationPdc2}
              isError={
                formik.errors.destinationPdc2 && formik.touched.destinationPdc2
              }
            />
            <div className="row mb-3">
              <div className=" d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleModal();
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="ms-2 btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  {action === Action.CREATE ? "Create" : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
