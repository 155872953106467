import React, { useState } from "react";
import CreatePT from "./CreatePT";
import PT from "./PT";
import DetailDate from "./DetailDate";
import InputPT from "./InputPT";
import Detail from "./Detail";
import NaturalLoin from "./InputNaturalLoin";

export default function CuttingPage() {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedPT, setSelectedPT] = useState();
  const [refresh, setRefresh] = useState();

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Cutting Line 2</h1>
      </div>
      <section className="section">
        <div className="row">
          <CreatePT setRefresh={setRefresh} />
          <PT
            setSelectedPT={setSelectedPT}
            setSelectedDate={setSelectedDate}
            refreshpt={refresh}
            selectedPT={selectedPT}
          />
          {selectedPT?.vendor != "NL" && (
            <>
              {selectedPT?.status == "input" && (
                <InputPT
                  selectedPT={selectedPT}
                  setSelectedPT={setSelectedPT}
                />
              )}
              {selectedPT?.status == "view" && (
                <DetailDate
                  selectedPT={selectedPT}
                  setSelectedPT={setSelectedPT}
                  setSelectedDate={setSelectedDate}
                  selectedDate={selectedDate}
                />
              )}
              {selectedDate && selectedPT && (
                <Detail
                  selectedDate={selectedDate}
                  selectedPT={selectedPT}
                  setSelectedDate={setSelectedDate}
                />
              )}
            </>
          )}
          {selectedPT?.vendor == "NL" && (
            <NaturalLoin
              selectedPT={selectedPT}
              setSelectedPT={setSelectedPT}
            />
          )}
        </div>
      </section>
    </main>
  );
}
