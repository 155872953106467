import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DataTable from "../../Components/DataTable";
import CSMInventory from "../../API/CSMInventory";
import fileDownload from "js-file-download";
import moment from "moment";

export default function VendorTable() {
  const _csminventory = new CSMInventory();
  const [selectedBatch, setSelectedBatch] = useState();
  const [selectedSap, setSelectedSap] = useState();

  const tableHeadersCSM = [
    {
      name: "Batch",
    },
  ];

  const tableBodyCSM = [
    {
      name: "batch",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={() => {
              setSelectedBatch(data.batch);
              setSelectedSap();
            }}
          >
            {data.batch}
          </a>
        );
      },
    },
  ];

  const tableHeadersCSMDetail = [
    {
      name: "SAP",
    },
    {
      name: "Batch",
    },
    {
      name: "Description",
    },
    {
      name: "Total Case",
    },
    {
      name: "Total Kgs",
    },
  ];

  const tableBodyCSMDetail = [
    {
      name: "sap",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedSap(data.sap);
            }}
          >
            {data.sap}
          </a>
        );
      },
    },
    {
      name: "batch",
    },
    {
      name: "description",
    },
    {
      name: "qty",
    },
    {
      name: "kgs",
      view: (data) => {
        if (data.weight) {
          return parseFloat(data.weight).toFixed(2);
        }
        return parseFloat(data.qty * data.kgs)?.toFixed(2);
      },
    },
  ];

  const tableHeadersCSMDetailSap = [
    {
      name: "PDC Line 2",
    },
    {
      name: "Super Batch",
    },
    {
      name: "LOT",
    },
    {
      name: "Total Case",
    },
    {
      name: "Total Kgs",
    },
  ];

  const tableBodyCSMDetailSap = [
    {
      name: "pdc",
      view: (data) => moment(data.pdc).format("DD-MM-YYYY"),
    },
    {
      name: "superBatch",
    },
    {
      name: "combined_lot",
    },
    {
      name: "inventoryQty",
    },
    {
      name: "kgs",
      view: (data) => {
        if (data.inventoryWeight) {
          return parseFloat(data.inventoryWeight).toFixed(2);
        }
        return parseFloat(data.inventoryQty * data.kgs)?.toFixed(2);
      },
    },
  ];

  const getInventoryReport = () => {
    _csminventory
      .GetInventoryCSMPacking2DetailReport(selectedBatch)
      .then((response) => {
        const fileName = `CS FG ${selectedBatch}.csv`;
        fileDownload(response.data, fileName);
      });
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Inventory</h1>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h5 className="card-title">Inventory FG</h5>
                </div>
                <DataTable
                  api={_csminventory.GetInventoryCSMPacking2}
                  tableHeader={tableHeadersCSM}
                  tableBody={tableBodyCSM}
                  activeClassName={(item) => {
                    if (item?.batch === selectedBatch) {
                      return "active";
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <Outlet />
        </div>
        {selectedBatch && (
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title">{selectedBatch}</h5>
                    <div className="d-flex gap-2">
                      <button
                        className="btn btn-primary"
                        onClick={getInventoryReport}
                      >
                        Download
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          setSelectedBatch();
                          setSelectedSap();
                        }}
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <DataTable
                    api={_csminventory.GetInventoryCSMPacking2Detail}
                    tableHeader={tableHeadersCSMDetail}
                    tableBody={tableBodyCSMDetail}
                    params={{ batch: selectedBatch }}
                    dependencies={[selectedBatch]}
                    activeClassName={(item) => {
                      if (item?.sap === selectedSap) {
                        return "active";
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <Outlet />
          </div>
        )}
        {selectedSap && (
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title">
                      {selectedBatch}-{selectedSap}
                    </h5>
                    <div className="d-flex gap-2">
                      {/* <button
                        className="btn btn-primary"
                        onClick={getInventoryReport}
                      >
                        Download
                      </button> */}
                      <button
                        className="btn btn-danger"
                        onClick={() => setSelectedSap()}
                      >
                        X
                      </button>
                    </div>
                  </div>
                  <DataTable
                    api={_csminventory.GetInventoryCSMPacking2DetailSAP}
                    tableHeader={tableHeadersCSMDetailSap}
                    tableBody={tableBodyCSMDetailSap}
                    params={{ batch: selectedBatch, sap: selectedSap }}
                    dependencies={[selectedBatch, selectedSap]}
                  />
                </div>
              </div>
            </div>
            <Outlet />
          </div>
        )}
      </section>
    </main>
  );
}
