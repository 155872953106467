import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Daily from "./Daily";
import Detail from "./Detail";

export default function Index() {
  const [selectedDate, setSelectedDate] = useState();
  const [dailyDate, setDailyDate] = useState();

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Packing Line 2</h1>
      </div>
      <section className="section">
        <div className="row">
          <Daily
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
          <Outlet />
          {selectedDate && (
            <Detail
              selectedDate={selectedDate}
              setDailyDate={setDailyDate}
              setSelectedDate={setSelectedDate}
              dailyDate={dailyDate}
            />
          )}
        </div>
      </section>
    </main>
  );
}
