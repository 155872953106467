import React from "react";
import CuttingModel from "../../API/CSMCutting";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../../Components";

export default function PackingList({ setRefresh }) {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const _cutting = new CuttingModel();

  const formik = useFormik({
    initialValues: {
      pt: "",
      vendor: "",
    },
    validationSchema: Yup.object({
      pt: Yup.string().required("PT is required"),
      vendor: Yup.object().required("Vendor is required"),
    }),
    onSubmit: (values) => {
      _cutting
        .CreateCutting({
          pt: values.pt,
          vendor: values.vendor.value,
        })
        .then(() => {
          setToastInfo({
            message: "pt successfully created",
            background: "success",
          });
          setIsShowToast(true);
          setRefresh((prev) => !prev);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "failed to get server",
            background: "danger",
          });
          setIsShowToast(true);
        });
    },
  });

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className=" mt-3 d-flex justify-content-between">
            <div className="d-flex">
              <div>
                <Input
                  type="number"
                  name="pt"
                  placeholder="Input PT"
                  useLabel={false}
                  value={formik.values.pt}
                  onChange={formik.handleChange}
                  errorMessage={formik.errors?.pt}
                  isError={formik.errors.pt && formik.touched.pt}
                />
              </div>
              <div>
                <Input
                  type="select"
                  name="vendor"
                  useLabel={false}
                  value={formik.values.vendor}
                  onChange={formik.handleChange}
                  options={[
                    { value: "TH", label: "TH" },
                    { value: "ID", label: "ID" },
                    { value: "NL", label: "Natural Loin" },
                    { value: "ML", label: "Mini Loin" },
                  ]}
                  errorMessage={formik.errors?.vendor}
                  isError={formik.errors.vendor && formik.touched.vendor}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
