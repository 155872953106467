import React, { useState } from "react";
import CSMLeftOver from "../../API/CSMLeftOver";
import { ModalPopUp, DataTable, ConfirmationModal } from "../../Components";
import CreateForm from "./CreateForm";
import fileDownload from "js-file-download";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import moment from "moment";
import { useModal } from "../../Hook/useModal";

export default function MonthlyTable({ setSelectedDate, selectedDate }) {
  const _csmleftover = new CSMLeftOver();
  const { lastDataModificationTimestamp, setToastInfo, setIsShowToast } =
    useApplicationStoreContext();
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState();

  const editModal = useModal();
  const confirmationModal = useModal();

  const component = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Left Over</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={editModal.toggleModal}
          />
        </div>
        <div className="modal-body">
          <CreateForm
            setIsOpen={editModal.toggleModal}
            uuid={selected?.uuid}
            setRefresh={setRefresh}
          />
        </div>
      </div>
    );
  };

  const handleExportDaily = (data) => {
    _csmleftover.LeftOverReport({ uuid: data.uuid }).then((response) => {
      const fileName = `Left Over ${moment(data.pdc).format("DD-MM-YYYY")} ${
        data.pt
      }.csv`;
      fileDownload(response.data, fileName);
    });
  };

  const tableHeader = [
    {
      name: "PDC",
    },
    {
      name: "PT",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "year",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedDate(data);
            }}
          >
            {moment(data.pdc).format("DD-MM-YYYY")}
          </a>
        );
      },
    },
    {
      name: "pt",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                editModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
            <button
              className="btn btn-info py-0 px-1 text-white"
              onClick={() => {
                handleExportDaily(data);
              }}
            >
              <i className="bi bi-download"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    _csmleftover
      .DeleteLeftOver(selected.uuid)
      .then(() => {
        setToastInfo({
          message: "packing successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch(() => {
        setToastInfo({
          message: "packing failed to delet",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Left Over</h5>
            <div className="align-items-center d-flex justify-content-between">
              <div className="d-flex">
                <button
                  className="btn btn-primary me-1"
                  onClick={() => {
                    setSelected("");
                    editModal.toggleModal();
                  }}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
          <DataTable
            api={_csmleftover.GetLeftOverGroupByPDC}
            pageSize={10}
            tableHeader={tableHeader}
            tableBody={tableBody}
            dependencies={[lastDataModificationTimestamp, refresh]}
            params={{ keyword: "" }}
            activeClassName={(item) => {
              if (
                item?.pdc === selectedDate?.pdc &&
                item.pt === selectedDate?.pt
              ) {
                return "active";
              }
            }}
          />
        </div>
      </div>
      <ModalPopUp
        component={component}
        isOpen={editModal.modal}
        setIsOpen={editModal.toggleModal}
        setRefresh={setRefresh}
      />
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        onSubmit={handleDelete}
        message={"Are you sure delete this item?"}
      />
    </div>
  );
}
