import { _Delete, _Get, _Patch, _Post } from "./base";
import Cookies from "js-cookie";

const endpoint = "csm/vendors";

class CSMVendor {
  constructor() {}

  PagedSearchVendor = ({ plantUuid, pageIndex, pageSize, category }) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/search`, {
      params: {
        plantUuid,
        pageIndex,
        pageSize,
        category,
      },
      ..._options,
    });
  };

  CreateVendor = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      data,
      _options
    );
  };

  ReadVendorByUuid = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
      ..._options,
    });
  };

  UpdateVendor = (uuid, data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
      data,
      _options
    );
  };

  DeleteVendor = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
      _options
    );
  };
}

export default CSMVendor;
