import React, { useEffect, useRef, useState } from "react";
import {
  ReadPacking,
  SaveShipmentItem,
  ReadShipmentDetail,
  DownloadShipmentReport,
  DeleteShipementItem,
} from "../../API";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Form } from "react-bootstrap";
import fileDownload from "js-file-download";

export default function Detail({ selectedShipment }) {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const ref = useRef();
  const [shipmentItems, setShipmentItems] = useState([]);
  const [row, setRow] = useState(1);

  const handleReadCode = (value) => {
    if (value.length !== 12) return;

    const isAlreadyAdded = shipmentItems.filter(
      (item) => item.caseNo === value
    );

    if (isAlreadyAdded.length > 0) {
      setToastInfo({
        message: `${value} already exist`,
        background: "danger",
      });
      setIsShowToast(true);
      ref.current.value = "";
      return;
    }

    ReadPacking(value).then((response) => {
      if (response.data.container) {
        setToastInfo({
          message: `Packing code already packed on ${response.data.container}`,
          background: "danger",
        });
        setIsShowToast(true);
        ref.current.value = "";
      } else {
        const maxShipmentOrder = shipmentItems
          .filter((item) => item.shipmentRow === row)
          .reduce(
            (max, item) =>
              item.shipmentOrder > max ? item.shipmentOrder : max,
            0
          );

        const model = {
          ...response.data,
          code: value,
          shipmentRow: row,
          shipmentOrder: maxShipmentOrder || 0,
        };
        ref.current.value = "";
        handleSaveShipment(model);
      }
    });
  };

  const handleSaveShipment = (model) => {
    SaveShipmentItem(selectedShipment.uuid, model)
      .then((response) => {
        setShipmentItems([model, ...shipmentItems]);
      })
      .catch((err) => {
        setToastInfo({
          message: "Item failed to add",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  useEffect(() => {
    if (selectedShipment) {
      ReadShipmentDetail(selectedShipment.uuid).then((response) => {
        setShipmentItems(response.data);
      });
    }
  }, [selectedShipment]);

  const handleDelete = (uuid) => {
    DeleteShipementItem(uuid).then((response) => {
      console.log(response);
      const data = shipmentItems.filter((item) => item.uuid !== uuid);

      setShipmentItems(data);
    });
  };

  const groupedData = shipmentItems.reduce((groups, item) => {
    const { shipmentRow, weight, grade } = item;

    if (!groups[shipmentRow]) {
      groups[shipmentRow] = {
        shipmentRow,
        totalPcs: 0,
        totalWeight: 0,
        totalTripleA_AB: 0,
        totalTripleA: 0,
        totalDoubleA: 0,
        totalSingleA: 0,
        totalAPlus: 0,
        totalBPlus: 0,
        totalB: 0,
        totalC: 0,
      };
    }

    groups[shipmentRow].totalPcs++;
    groups[shipmentRow].totalWeight += parseFloat(weight);
    if (grade === "AAA(AB)") {
      groups[shipmentRow].totalTripleA_AB++;
    }
    if (grade === "AAA") {
      groups[shipmentRow].totalTripleA++;
    }
    if (grade === "AA") {
      groups[shipmentRow].totalDoubleA++;
    }
    if (grade === "A") {
      groups[shipmentRow].totalSingleA++;
    }
    if (grade === "A+") {
      groups[shipmentRow].totalAPlus++;
    }
    if (grade === "B+") {
      groups[shipmentRow].totalBPlus++;
    }
    if (grade === "B") {
      groups[shipmentRow].totalB++;
    }
    if (grade === "C") {
      groups[shipmentRow].totalC++;
    }

    return groups;
  }, {});

  const totalPcs = (filter) => {
    return Object.values(groupedData).reduce(
      (sum, item) => sum + parseFloat(item[filter]),
      0
    );
  };

  const totalWeight = () => {
    return Object.values(groupedData).reduce(
      (sum, item) => sum + parseFloat(item.totalWeight),
      0
    );
  };

  const handleReport = () => {
    DownloadShipmentReport(selectedShipment.uuid)
      .then((response) => {
        const fileNameDetail = `shipment detail(${selectedShipment.invoice}) - ${selectedShipment.etd}.csv`;
        const fileNamePackingList = `shipment PL(${selectedShipment.invoice}) - ${selectedShipment.etd}.csv`;
        fileDownload(response.data.detailData, fileNameDetail);
        fileDownload(response.data.packingList, fileNamePackingList);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "data not found",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className="col-xl-12">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{selectedShipment.invoice}</h5>
          <div className="col-xl-4">
            <table className="table">
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Pcs</th>
                  <th>Total</th>
                  <th>AAA(AB)</th>
                  <th>AAA</th>
                  <th>AA</th>
                  <th>A</th>
                  <th>A+</th>
                  <th>B+</th>
                  <th>B</th>
                  <th>C</th>
                </tr>
              </thead>
              <tbody>
                {groupedData &&
                  Object.values(groupedData).map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.shipmentRow}</td>
                        <td>{item.totalPcs}</td>
                        <td>{item.totalWeight.toFixed(2)}</td>
                        <td>{item.totalTripleA_AB}</td>
                        <td>{item.totalTripleA}</td>
                        <td>{item.totalDoubleA}</td>
                        <td>{item.totalSingleA}</td>
                        <td>{item.totalAPlus}</td>
                        <td>{item.totalBPlus}</td>
                        <td>{item.totalB}</td>
                        <td>{item.totalC}</td>
                      </tr>
                    );
                  })}
              </tbody>
              {groupedData && Object.values(groupedData) && (
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <th>{totalPcs("totalPcs")}</th>
                    <th>{Number(totalWeight()).toFixed(2)}</th>
                    <th>{totalPcs("totalTripleA_AB")}</th>
                    <th>{totalPcs("totalTripleA")}</th>
                    <th>{totalPcs("totalDoubleA")}</th>
                    <th>{totalPcs("totalSingleA")}</th>
                    <th>{totalPcs("totalAPlus")}</th>
                    <th>{totalPcs("totalBPlus")}</th>
                    <th>{totalPcs("totalB")}</th>
                    <th>{totalPcs("totalC")}</th>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>

          <div className="d-flex justify-content-between align-items-end">
            <div className="col-md-10 mt-3 row">
              <div className="col-md-4">
                <label htmlFor="code">Packing Code</label>
                <input
                  id="code"
                  ref={ref}
                  type="text"
                  className="form-control"
                  onChange={(e) => handleReadCode(e.target.value)}
                  maxLength="19"
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="row">Row</label>
                <Form.Select
                  name="shipmentRow"
                  defaultValue={1}
                  onChange={(e) => setRow(parseInt(e.target.value))}
                  value={row}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                  <option value={15}>15</option>
                </Form.Select>
              </div>
            </div>
            <div>
              <button className="btn btn-primary me-1" onClick={handleReport}>
                Download
              </button>
              {/* <button className="btn btn-primary" onClick={handleSaveShipment}>
                Save
              </button> */}
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Packing date</th>
                <th scope="col">PDC</th>
                <th scope="col">Fleet</th>
                <th scope="col">Grade</th>
                <th scope="col">Pcs</th>
                <th scope="col">Weight</th>
                <th scope="col">Certificate</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {shipmentItems &&
                shipmentItems
                  .filter((a) => a.shipmentRow === row)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">
                          <a
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {item.caseNo}
                          </a>
                        </th>
                        <td>{item.packingDate}</td>
                        <td>{item.pdc}</td>
                        <td>{item.fleet}</td>
                        <td>{item.grade}</td>
                        <td>{item.pcs}</td>
                        <td>{item.weight}</td>
                        <td>{item.certificateType}</td>
                        <td>
                          <button
                            className="btn btn-danger py-0 px-1"
                            onClick={() => handleDelete(item.uuid)}
                          >
                            <i className="bi bi-trash3"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
