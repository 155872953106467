import React, { useState } from "react";
import CSMInventory from "../../API/CSMInventory";
import { DataTable } from "../../Components";
import fileDownload from "js-file-download";
import { debounce } from "lodash";
import moment from "moment";

export default function Monthly({ inventory }) {
  const _inventory = new CSMInventory();

  const getInventoryReport = () => {
    _inventory.ExportInventoryDaily(inventory).then((response) => {
      const fileName = `packing(${inventory}).csv`;
      fileDownload(response.data, fileName);
    });
  };
  const [data, setData] = useState([]);

  const tableHeader = [
    {
      name: "Sack No",
    },
    {
      name: "Lot",
    },
    {
      name: "Grade",
    },
    {
      name: "Weight",
    },
    {
      name: "Packing Date",
    },
  ];

  const tableBody = [
    {
      name: "sackNo",
    },
    {
      name: "lot",
    },
    {
      name: "grade",
    },
    {
      name: "totalWeight",
    },
    {
      name: "packingDate",
      view: (data) => moment(data.packingDate).format("DD-MM-YYYY"),
    },
  ];

  const handleDebouncedChange = debounce((value) => {
    if (value) {
      _inventory
        .GetSearchByLot(inventory, value)
        .then((response) => setData(response.data.data));
    } else {
      setData([]);
    }
  }, 500);

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">CS</h5>
            <div className="d-flex gap-2">
              <button
                className="btn btn-primary me-1"
                onClick={getInventoryReport}
              >
                Download
              </button>
            </div>
          </div>
          <DataTable
            api={_inventory.GetPDC}
            pageSize={10}
            params={{ inventory }}
            tableHeader={tableHeader}
            tableBody={tableBody}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">History</h5>
          </div>
          <div className="row">
            <div className="col-xl-2">
              <input
                type="text"
                className="form-control"
                placeholder="Search By Lot"
                onChange={(e) => handleDebouncedChange(e.target.value)}
              />
            </div>
          </div>
          <DataTable
            tableHeader={tableHeader}
            tableBody={tableBody}
            data={data}
          />
        </div>
      </div>
    </div>
  );
}
