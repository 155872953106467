import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";

export default function DataTable({
  data,
  api,
  size,
  params,
  tableHeader,
  tableBody,
  dependencies,
  activeClassName,
  conditionalLoad = true,
  isSearch = false,
}) {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(size || 10);
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (conditionalLoad) {
      setIsLoading(true);
      api &&
        api({ pageIndex, pageSize, ...(params || {}) }).then((response) => {
          setResult(response.data.data);
          setTotalCount(response.data.totalCount);
          setIsLoading(false);
        });
    }
  }, [pageIndex, ...(dependencies || [])]);

  const handlePageChange = (e) => {
    setPageIndex(e.selected);
  };

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / pageSize));
  }, [totalCount]);

  useEffect(() => {
    if (data) {
      setResult(data);
      setIsLoading(false);
    }
  }, [data]);

  const handleDebouncedChange = debounce((value) => {
    setIsLoading(true);
    api({
      pageIndex,
      pageSize,
      keyword: value,
      ...(params || {}),
    }).then((response) => {
      setResult(response.data.data);
      setTotalCount(response.data.totalCount);
      setIsLoading(false);
    });
  }, 300);

  return (
    <>
      {isSearch && (
        <div className="mb-2 row">
          <div className="col-sm-3">
            <input
              className="form-control search-box me-2 mb-2 d-inline-block"
              placeholder="Search..."
              onChange={(e) => handleDebouncedChange(e.target.value)}
            />
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <table className="table">
          <thead>
            <tr>
              {tableHeader &&
                tableHeader.map((item, index) => {
                  return (
                    <th scope="col" key={index}>
                      {item.name}
                    </th>
                  );
                })}
            </tr>
          </thead>
          <tbody>
            {result.map((item, index) => {
              return (
                <tr
                  key={index}
                  className={activeClassName && activeClassName(item)}
                >
                  {tableBody &&
                    tableBody.map((row, index) => {
                      return (
                        <td key={index}>
                          {row.view ? row.view(item) : item[row.name]}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {totalCount > pageSize && (
        <nav>
          <ReactPaginate
            activeClassName="active"
            breakLabel={"..."}
            breakLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            previousLabel={"Previous"}
            nextLabel={"Next"}
            nextClassName="page-item"
            nextLinkClassName="page-link"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            breakClassName="page-item"
            className="pagination justify-content-end"
            onPageChange={handlePageChange}
            hrefBuilder={(page, pageCount, selected) => "#"}
            hrefAllControls={true}
          />
        </nav>
      )}
    </>
  );
}
