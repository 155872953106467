import React, { useRef, useState } from "react";
import CuttingModel from "../../API/CSMCutting";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { DataTable } from "../../Components";
import moment from "moment";
import { debounce } from "lodash";

export default function Detail({ selectedPT, setSelectedPT }) {
  const _cutting = new CuttingModel();
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const [packingList, setPackingList] = useState([]);
  const [cuttingDate, setCuttingDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [code, setCode] = useState("");
  const ref = useRef();

  const handleDelete = (data) => {
    const afterDeleted = packingList.filter((item) => item.uuid !== data.uuid);

    setPackingList([...afterDeleted]);
  };

  const handleSave = () => {
    if (selectedPT.vendor == "ID") {
      _cutting
        .CreateCuttingDetailIndo(packingList)
        .then((response) => {
          setToastInfo({
            message: "cutting successfully updated",
            background: "success",
          });
          setIsShowToast(true);
          setSelectedPT();
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "cutting failed updated",
            background: "danger",
          });
          setIsShowToast(true);
        });

      return;
    }

    _cutting
      .CreateCuttingDetail(packingList)
      .then((response) => {
        setToastInfo({
          message: "cutting successfully updated",
          background: "success",
        });
        setIsShowToast(true);
        setSelectedPT();
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "cutting failed updated",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const handleReadCode = (lotCode) => {
    if (selectedPT.vendor == "ID") {
      const splited = lotCode.split(/[\/\n^ ]+/);
      _cutting.GetCSMCode({ caseNumber: splited[0] }).then((response) => {
        if (response.data.data.length > 0) {
          const updated = response.data.data.map((item) => ({
            ...item,
            code,
            cuttingDate,
            cuttingUuid: selectedPT.uuid,
          }));
          setPackingList([...updated, ...packingList]);

          ref.current.value = "";
        }
      });

      return;
    }

    if (lotCode.length == 36) {
      _cutting
        .GetPackingUuid({
          uuid: lotCode,
        })
        .then((response) => {
          if (response.data) {
            setPackingList([
              {
                ...response.data,
                cuttingDate: cuttingDate,
                cuttingUuid: selectedPT.uuid,
                packingUuid: response.data.uuid,
              },
              ...packingList,
            ]);
          }
        });

      ref.current.value = "";
    }
  };

  const tableHeader = [
    {
      name: "Sack No",
    },
    {
      name: "Packing Date",
    },
    {
      name: "Cutting Date",
    },
    {
      name: "Weight",
    },
    {
      name: "Grade",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "sackNo",
    },
    {
      name: "packingDate",
    },
    {
      name: "cuttingDate",
      view: (data) => moment(data.cuttingDate).format("DD-MM-YYYY"),
    },
    {
      name: "totalWeight",
      view: (data) => parseFloat(data.totalWeight).toFixed(2),
    },
    {
      name: "grade",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-danger py-0 px-1"
              onClick={() => {
                handleDelete(data);
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const tableHeaderIndo = [
    {
      name: "Case No",
    },
    {
      name: "Vendor",
    },
    {
      name: "Certificate",
    },
    {
      name: "Grade",
    },
    {
      name: "Fleet",
    },
    {
      name: "Weight",
    },
    {
      name: "Code",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyIndo = [
    {
      name: "caseNumber",
    },
    {
      name: "vendor",
    },
    {
      name: "certificate",
    },
    {
      name: "grade",
    },
    {
      name: "fleet",
    },
    {
      name: "boxWeight",
    },
    {
      name: "code",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-danger py-0 px-1"
              onClick={() => {
                handleDelete(data);
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDebouncedChange = debounce((value) => {
    handleReadCode(value);
  }, 300); // 300 milliseconds debounce delay

  // Wrapper function to handle onChange with debounce
  const handleInputChange = (e) => {
    handleDebouncedChange(e.target.value);
  };

  if (!selectedPT) {
    return null;
  }

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Detail {selectedPT.pt}</h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedPT();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <input
                ref={ref}
                type="text"
                className="form-control"
                onChange={handleInputChange}
                placeholder="Scan QR Barcode"
              />
              {selectedPT?.vendor == "ID" && (
                <input
                  type="text"
                  className="form-control ms-2"
                  onChange={(e) => setCode(e.target.value)}
                  placeholder="input csm code"
                  value={code}
                />
              )}
            </div>
            <div className="d-flex align-items-center">
              <input
                type="date"
                className="form-control me-1"
                value={cuttingDate}
                onChange={(e) => setCuttingDate(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  handleSave();
                }}
              >
                Save
              </button>
            </div>
          </div>
          <DataTable
            data={packingList}
            tableHeader={
              selectedPT?.vendor === "ID" ? tableHeaderIndo : tableHeader
            }
            tableBody={selectedPT?.vendor === "ID" ? tableBodyIndo : tableBody}
            dependencies={[selectedPT.uuid]}
          />
        </div>
      </div>
    </div>
  );
}
