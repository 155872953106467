import React, { useEffect, useState } from "react";
import CSMReceiving from "../../API/CSMReceiving";
import CSMVendor from "../../API/CSMvendor";
import CSMRetouching from "../../API/CSMRetouching";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Input } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Action } from "../../Constant";
import moment from "moment";
import { handleSizeCSMTrimming } from "../../Utility";
import jsPDF from "jspdf";
import QRCode from "qrcode";

export default function CreateForm({ uuid, setIsOpen }) {
  const { setToastInfo, setIsShowToast, setLastDataModificationTimestamp } =
    useApplicationStoreContext();
  const _CSMReceiving = new CSMReceiving();
  const _CSMVendor = new CSMVendor();
  const _CSMRetouching = new CSMRetouching();

  const [action, setAction] = useState(Action.CREATE);
  const [totalCountDailyProduction, setTotalCountDailyProduction] = useState(0);

  const formik = useFormik({
    initialValues: {
      retouchingDate: moment(new Date()).format("YYYY-MM-DD"),
      receiving: "",
      grade: "",
      weight: "",
      vendor: "",
    },
    validationSchema: Yup.object({
      receiving: Yup.object().required("receiving date is required"),
      retouchingDate: Yup.string().required("date is required"),
      grade: Yup.string().required("grade is required"),
      weight: Yup.number().required("weight is required"),
      vendor: Yup.object().required("vendor is required"),
    }),
    onSubmit: (values) => {
      if (action === Action.CREATE) {
        const data = {
          weight: values.weight,
          receivingUuid: values.receiving.value,
          grade: values.grade,
          retouchingDate: values.retouchingDate,
        };
        _CSMRetouching
          .CreateNewRetouching(data)
          .then((response) => {
            setToastInfo({
              message: "Retouching successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date());
            handleReadTotalCount();
            formik.setFieldValue("weight", "");

            const receiving = {
              value: response.data.trimmingDate,
              label: response.data.reff + response.data.pdc,
              uuid: response.data.CSMReceivingUuid,
            };

            if (values.grade !== "YAKI") {
              exportPDF({
                ...response.data,
                vendor: { label: response.data.rmLotCode },
                receiving: receiving,
                fishName: values.vendor.fishName,
                fishCode: values.vendor.fishCode,
              });
            }
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Retouching failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        const data = {
          weight: formik.values.weight,
          receivingUuid: formik.values.CSMReceivingUuid,
          grade: formik.values.grade,
          retouchingDate: formik.values.retouchingDate,
        };
        _CSMRetouching
          .UpdateRetouching(uuid, data)
          .then((response) => {
            setToastInfo({
              message: "Retouching successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen(false);
            setLastDataModificationTimestamp(new Date());
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Retouching failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  useEffect(() => {
    if (uuid) {
      setAction(Action.VIEW);
      _CSMRetouching
        .ReadRetouchingByUuid(uuid)
        .then((response) => {
          formik.setValues({
            ...response.data,
            vendor: {
              value: response.data.vendorUuid,
              label: response.data.rmLotCode,
            },
            receiving: {
              value: response.data.receivingsUuid,
              label:
                response.data.reff +
                moment(response.data.receivingDate).format("DDMMYY"),
            },
          });
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response?.status === 403
                ? err.response?.data?.message
                : "failed to get server",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  }, [uuid]);

  const handleReadTotalCount = () => {
    _CSMRetouching
      .ReadRetouchingTotalCountDaily({
        receivingUuid: formik.values.receiving.value,
        retouchingDate: formik.values.retouchingDate,
      })
      .then((response) => {
        setTotalCountDailyProduction(response.data.totalCount);
      });
  };

  useEffect(() => {
    if (formik.values.receiving?.value) {
      handleReadTotalCount();
    }
  }, [formik.values.receiving]);

  const exportPDF = (data) => {
    var doc = new jsPDF("l", "mm", [100, 50]);

    QRCode.toDataURL(
      `${data.vendor.label}/${data.grade}/${data.weight}/${data.receiving.label}/${data.origin}/${data.receivingDate}/${data.materialType}/${data.retouchingDate}`,
      { margin: 1 }
    )
      .then((url) => {
        const fontSize = 6;
        const fontFamily = "helvetica";
        const fontWeight = "bold";

        doc.setFont(fontFamily, fontWeight);
        doc.setFontSize(fontSize);
        doc.text(`FROZEN ${data.fishName || "YELLOFIN TUNA"} LOIN`, 50, 3);
        doc.text("Species", 50, 7);
        doc.text("Grade", 65, 7);
        doc.text("Weight (Kg)", 80, 7);
        doc.text(`${data.fishCode || "YFT"}`, 50, 10);
        doc.text(data.grade, 65, 11);
        doc.text(`${data.weight}`, 80, 11);
        doc.text(data.vendor.label, 50, 14);
        doc.text("Internal Lot Code", 50, 18);
        doc.text("Remarks", 70, 18);
        doc.text(data.receiving.label, 50, 21);
        doc.text("Origin", 50, 25);
        doc.text("Fishing ground", 60, 25);
        doc.text(data.origin, 50, 28);
        doc.text(`PDC: ${data.receivingDate}`, 50, 32);
        doc.text(`Retouching Date: ${data.retouchingDate}`, 50, 36);
        doc.text(`Certificate : ${data.materialType}`, 50, 40);
        doc.text("Keep Frozen -18°C", 50, 44);
        doc.text("Allergen Statement Contain Fish (Tuna)", 50, 48);

        // source, format, x, y ,width, height
        doc.addImage(url, "JPEG", 5, 10, 30, 30);

        doc.autoPrint();
        var iframe = document.createElement("iframe");
        iframe.src = doc.output("datauristring");
        iframe.style.width = "0";
        iframe.style.height = "0";
        document.body.appendChild(iframe);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <form>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h6 className="mb-0">
          Total Count Daily Production {totalCountDailyProduction}
        </h6>
        {uuid && action === Action.VIEW && formik.values.grade !== "YAKI" && (
          <button
            className="btn py-0 me-2"
            onClick={(e) => {
              e.preventDefault();
              exportPDF({
                ...formik.values,
              });
            }}
          >
            <i className="bi bi-printer"></i>
          </button>
        )}
      </div>
      <Input
        label="RM Lot Code"
        type="select-api"
        name="vendor"
        onChange={(e) => {
          if (formik.values.receiving) {
            formik.setFieldValue("receiving", "");
          }

          formik.setFieldValue("vendor", e.target.value);
        }}
        value={formik.values.vendor}
        errorMessage={formik.errors?.vendor}
        isError={formik.errors.vendor && formik.touched.vendor}
        disabled={action === Action.VIEW && true}
        api={_CSMVendor.PagedSearchVendor}
        handleSetOptions={(pt) => ({
          value: pt.uuid,
          label: pt.rmLotCode,
          reff: pt.reff,
          fishName: pt.fishName,
          fishCode: pt.fishCode,
        })}
      />
      <Input
        label="CSM PDC Lot"
        type="select-api"
        name="receiving"
        value={formik.values.receiving}
        onChange={formik.handleChange}
        errorMessage={formik.errors?.receiving}
        isError={formik.errors.receiving && formik.touched.receiving}
        disabled={action === Action.VIEW && true}
        api={_CSMReceiving.ReadPDCByVendorUuid}
        additionalParams={{ uuid: formik.values?.vendor?.value }}
        handleSetOptions={(pt) => ({
          value: pt.uuid,
          label: pt.reff + pt.pdc,
        })}
      />
      <Input
        label="Date"
        type="date"
        name="retouchingDate"
        onChange={formik.handleChange}
        value={formik.values.retouchingDate}
        errorMessage={formik.errors?.retouchingDate}
        isError={formik.errors.retouchingDate && formik.touched.retouchingDate}
      />
      <Input
        label="Weight"
        type="number"
        name="weight"
        onChange={formik.handleChange}
        value={formik.values.weight}
        placeholder="Input Weight"
        errorMessage={formik.errors?.weight}
        isError={formik.errors.weight && formik.touched.weight}
        onKeyDown={(e) => e.key === "Enter" && formik.handleSubmit()}
      />
      <Input
        label="Size"
        type="text"
        name="size"
        value={handleSizeCSMTrimming(formik.values.weight)}
        disabled={true}
      />
      <Input
        label="Grade"
        type="checkbox"
        name="grade"
        onChange={formik.handleChange}
        value={formik.values.grade}
        errorMessage={formik.errors?.grade}
        isError={formik.errors.grade && formik.touched.grade}
        options={["AAA", "AA", "A", "B", "A1", "A2", "YAKI"]}
      />

      <div className="row mb-3">
        <div className=" d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="ms-2 btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {action === Action.CREATE ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
