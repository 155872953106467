import React, { useEffect, useState } from "react";
import { intToMonth } from "../../Utility";
import { SearchShipment, DeleteShipement } from "../../API";
import Pagination from "../../Components/Pagination";
import { useApplicationStoreContext } from "../../Hook/UserHook";

export default function ShipmentTable({
  date,
  year,
  month,
  setSelectedShipment,
  setEditShipment,
  setIsOpenModal,
  isReload,
}) {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 10;
  const [data, setData] = useState();

  const getShipment = () => {
    SearchShipment(year, month, pageIndex, pageSize).then((response) => {
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
    });
  };

  useEffect(() => {
    if (date) {
      getShipment();
    }
  }, [date, isReload]);

  const deleteShipment = (uuid) => {
    DeleteShipement(uuid)
      .then(() => {
        setToastInfo({
          message: "Shipment successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        getShipment();
        setSelectedShipment();
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "Shipment failed to delete",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            {intToMonth(month)} - {year}
          </h5>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">INV No</th>
                <th scope="col">PL No</th>
                <th scope="col">ETD</th>
                <th scope="col">BL No</th>
                <th scope="col">Container</th>
                <th scope="col">Vessel</th>
                <th scope="col">Ref</th>
                <th scope="col">Port</th>
                <th scope="col">Dischargerd</th>
                <th scope="col">SAP PO</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">
                        <a
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedShipment(item);
                          }}
                        >
                          {item.invoice}
                        </a>
                      </th>
                      <td>{item.packingList}</td>
                      <td>{item.etd}</td>
                      <td>{item.bl}</td>
                      <td>{item.container}</td>
                      <td>{item.vesselName}</td>
                      <td>{item.shipmentRef}</td>
                      <td>{item.portLoading}</td>
                      <td>{item.portDischarged}</td>
                      <td>{item.po}</td>
                      <td>
                        <button
                          className="btn btn-primary py-0 px-1 me-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditShipment(item);
                            setIsOpenModal(true);
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#verticalycentered"
                        >
                          <i className="bi bi-pencil-square"></i>
                        </button>
                        <button
                          className="btn btn-danger py-0 px-1"
                          onClick={(e) => {
                            e.preventDefault();
                            deleteShipment(item.uuid);
                          }}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalCount !== 0 && totalCount > pageSize && (
            <nav>
              <Pagination
                pageSize={pageSize}
                totalCount={totalCount}
                pageOffset={pageOffset}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPageIndex={setPageIndex}
                setPageOffset={setPageOffset}
              />
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
