import { _Delete, _Get, _Patch, _Post } from "./base";
import Cookies from "js-cookie";

const endpoint = "csm/leftover";

class CSMCutting {
  constructor() {}

  GetLeftOverByPT = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  GetLeftOverGroupByPDC = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/pdc`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  GetLeftOverDetail = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
      ..._options,
    });
  };

  CreateLeftOver = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      data,
      _options
    );
  };

  DeleteLeftOver = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
      ..._options,
    });
  };

  UpdateLeftOver = (uuid, data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
      data,
      {
        ..._options,
      }
    );
  };

  CreateLeftOverDetail = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail`,
      data,
      _options
    );
  };

  LeftOverDetailSearh = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  DeleteLeftOverDetail = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/${uuid}`,
      {
        ..._options,
      }
    );
  };

  GetLeftOverDetail2 = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail/${uuid}`, {
      ..._options,
    });
  };

  UpdateLeftOverDetail = (uuid, data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/${uuid}`,
      data,
      {
        ..._options,
      }
    );
  };

  LeftOverReport = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/report`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };
}

export default CSMCutting;
