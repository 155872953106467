import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import MainLayout from "../Pages/MainLayout";
import {
  PlantForm,
  PlantTable,
  VendorTable,
  VendorForm,
  TrimmingTable,
  RetouchingTable,
  Packing,
  Receiving,
  Inventory,
  Shipment,
  CSMVendorForm,
  CSMVendorTable,
  CSMReceivingTable,
  CSMTrimming,
  CSMRetouching,
  CSMPacking,
  CSMInventory,
  CSMCutting,
  CSMPacking2,
  CSMLeftOver,
  CSMShipment,
  CSMInventoryPacking,
  CSMRepack,
} from "../Pages";
import { CSM } from "../Constant";

export default function AllRoutes() {
  const accessToken = "accessToken";

  const [plant, setPlant] = useState();

  useEffect(() => {
    const accessTokenCookie = Cookies.get(accessToken);
    const decoded = jwt_decode(accessTokenCookie);
    setPlant({
      code: decoded.plant,
      uuid: decoded.plantUuid,
    });
  }, [Cookies.get(accessToken)]);

  if (!plant) return;

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        {plant.code === CSM ? (
          <>
            <Route path="plant" element={<PlantTable />}>
              <Route path="add" element={<PlantForm />} />
              <Route path="view/:uuid" element={<PlantForm />} />
            </Route>
            <Route path="vendor" element={<CSMVendorTable />}>
              <Route path="add" element={<CSMVendorForm />} />
              <Route path="view/:uuid" element={<CSMVendorForm />} />
            </Route>
            <Route path="receiving" element={<CSMReceivingTable />} />
            <Route path="trimming" element={<CSMTrimming />} />
            <Route path="retouching" element={<CSMRetouching />} />
            <Route path="packing" element={<CSMPacking />} />
            <Route path="inventory/:inventory" element={<CSMInventory />} />
            <Route
              path="inventory/packing2/csm"
              element={<CSMInventoryPacking />}
            />
            <Route path="cutting" element={<CSMCutting />} />
            <Route path="packing2" element={<CSMPacking2 />} />
            <Route path="leftover" element={<CSMLeftOver />} />
            <Route path="shipment" element={<CSMShipment />} />
            <Route path="repack" element={<CSMRepack />} />
          </>
        ) : (
          <>
            <Route path="plant" element={<PlantTable />}>
              <Route path="add" element={<PlantForm />} />
              <Route path="view/:uuid" element={<PlantForm />} />
            </Route>
            <Route path="vendor" element={<VendorTable />}>
              <Route path="add" element={<VendorForm />} />
              <Route path="view/:uuid" element={<VendorForm />} />
            </Route>
            <Route path="receiving" element={<Receiving />} />
            <Route path="trimming" element={<TrimmingTable />} />
            <Route path="retouching" element={<RetouchingTable />} />
            <Route path="inventory/:inventory" element={<Inventory />} />
            <Route path="packing" element={<Packing />} />
            <Route path="shipment" element={<Shipment />} />
          </>
        )}
      </Route>
    </Routes>
  );
}
