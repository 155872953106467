import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PagedSearchVendor } from "../../API";
import { Outlet } from "react-router-dom";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import DataTable from "../../Components/DataTable";

export default function VendorTable() {
  const { plant, lastDataModificationTimestamp } = useApplicationStoreContext();
  const navigate = useNavigate();

  const tableHeadersMTM = [
    {
      name: "Supplier",
    },
    {
      name: "Code",
    },
    {
      name: "Certificate",
    },
    {
      name: "Fleet",
    },
    {
      name: "Fisherman Group",
    },
    {
      name: "Material",
    },
  ];

  const tableBodyMTM = [
    {
      name: "supplierName",
      view: (data) => {
        return (
          <a
            href={`/view/${data.uuid}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`view/${data.uuid}`);
            }}
          >
            {data.supplierName}
          </a>
        );
      },
    },
    {
      name: "vendorCode",
    },
    {
      name: "certificateType",
    },
    {
      name: "fleet",
    },
    {
      name: "owner",
    },
    {
      name: "rawMaterialType",
    },
  ];

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Vendor</h1>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h5 className="card-title">Vendor Table</h5>
                  <div className="row align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("add");
                      }}
                    >
                      Create New
                    </button>
                  </div>
                </div>
                <DataTable
                  api={PagedSearchVendor}
                  pageSize={10}
                  tableHeader={tableHeadersMTM}
                  tableBody={tableBodyMTM}
                  params={{ plantUuid: plant?.uuid }}
                  dependencies={[lastDataModificationTimestamp]}
                />
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </section>
    </main>
  );
}
