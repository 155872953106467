import { _Delete, _Get, _Patch, _Post } from "./base";
import Cookies from "js-cookie";

const endpoint = "csm/shipment";

class CSMShipment {
  constructor() {}

  PagedSearchShipment = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CreateShipment = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      data,
      _options
    );
  };

  ReadShipmentByUuid = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
      ..._options,
    });
  };

  UpdateShipment = (uuid, data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
      data,
      _options
    );
  };

  DeleteShipment = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
      _options
    );
  };

  ReadQRCode = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/qr`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CreateShipmentDetail = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail`,
      data,
      _options
    );
  };

  PagedSearchShipmentDetail = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  DeleteShipmentDetail = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/${uuid}`,
      _options
    );
  };

  UpdateShipmentDetail = (uuid, data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/${uuid}`,
      data,
      _options
    );
  };

  ShipmentDetailSummarize = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/summarize`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  ShipmentReport = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/report`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CreateShipmentPlan = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}/plan`,
      data,
      _options
    );
  };

  UpdateShipmentPlan = (uuid, data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/plan/${uuid}`,
      data,
      _options
    );
  };

  PagedSearchShipmentPlan = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/plan/${params.shipmentUuid}`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  DeleteShipmentPlan = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/plan/${uuid}`,
      _options
    );
  };
}

export default CSMShipment;
