import React, { useEffect, useState } from "react";
import CSMShipment from "../../API/CSMShipment";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Input } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Action } from "../../Constant";
import moment from "moment";

export default function CreateForm({ uuid, setIsOpen, setRefresh }) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const _csmshipment = new CSMShipment();
  const [action, setAction] = useState(Action.CREATE);

  const formik = useFormik({
    initialValues: {
      invoice: "",
      destination: "",
      date: "",
      po: "",
    },
    validationSchema: Yup.object({
      po: Yup.string().required("PO is required"),
      invoice: Yup.string().required("Invoice is required"),
      destination: Yup.string().required("Destination is required"),
      date: Yup.date().required("Loading date is required"),
    }),
    onSubmit: (values) => {
      const data = {
        po: values.po,
        invoice: values.invoice,
        destination: values.destination,
        date: values.date,
      };

      if (action === Action.CREATE) {
        _csmshipment
          .CreateShipment(data)
          .then((response) => {
            setToastInfo({
              message: "shipment successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen();
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "shipment failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        _csmshipment
          .UpdateShipment(uuid, data)
          .then((response) => {
            setToastInfo({
              message: "shipment successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen();
            // setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "shipment failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  useEffect(() => {
    if (uuid) {
      setAction(Action.EDIT);
      _csmshipment.ReadShipmentByUuid(uuid).then((response) => {
        response.data.date = moment(response.data.date).format("YYYY-MM-DD");
        formik.setValues(response.data);
      });
    }
  }, [uuid]);

  return (
    <form>
      <Input
        label="PO"
        type="text"
        name="po"
        onChange={formik.handleChange}
        value={formik.values.po}
        placeholder="Input PO"
        errorMessage={formik.errors?.po}
        isError={formik.errors.po && formik.touched.po}
      />
      <Input
        label="Invoice"
        type="text"
        name="invoice"
        onChange={formik.handleChange}
        value={formik.values.invoice}
        placeholder="Input invoice"
        errorMessage={formik.errors?.invoice}
        isError={formik.errors.invoice && formik.touched.invoice}
      />
      <Input
        label="Destination"
        type="text"
        name="destination"
        onChange={formik.handleChange}
        value={formik.values.destination}
        placeholder="Input destination"
        errorMessage={formik.errors?.destination}
        isError={formik.errors.destination && formik.touched.destination}
      />
      <Input
        label="Loading date"
        type="date"
        name="date"
        onChange={formik.handleChange}
        value={formik.values.date}
        errorMessage={formik.errors?.date}
        isError={formik.errors.date && formik.touched.date}
      />
      <div className="row mb-3">
        <div className=" d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen();
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="ms-2 btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {action === Action.CREATE ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
