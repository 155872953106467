import React, { useEffect, useState } from "react";
import { CreatePacking, ReadMaxBox } from "../../API";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { addLeadingZero, handleSize } from "../../Utility";
import jsPDF from "jspdf";
import QRCode from "qrcode";

export default function PackingList({ packingList, setPackingList }) {
  const {
    setIsShowToast,
    setToastInfo,
    setLastDataModificationTimestamp,
    lastDataModificationTimestamp,
  } = useApplicationStoreContext();

  const [maxBox, setMaxBox] = useState(0);
  const [packingDate, setPackingDate] = useState(
    new Date().toISOString().slice(0, 10)
  );

  useEffect(() => {
    const month = packingDate.split("-")[1];
    const year = packingDate.split("-")[0];
    ReadMaxBox(parseInt(year), parseInt(month)).then((response) => {
      if (response.data.max) {
        setMaxBox(response.data.max);
      } else {
        setMaxBox(0);
      }
    });
  }, [packingDate, lastDataModificationTimestamp]);

  const handleSubmit = () => {
    CreatePacking({
      packingDate: packingDate,
      packingList: packingList,
      packingBox: maxBox + 1,
    })
      .then(() => {
        setToastInfo({
          message: "new box successfully created",
          background: "success",
        });
        setIsShowToast(true);
        exportPdf();
        setLastDataModificationTimestamp(new Date().getTime());
        setPackingList([]);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "failed to get server",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const handleDelete = (index) => {
    const list = [...packingList];
    list.splice(index, 1);
    setPackingList(list);
  };

  const maxPDC = () => {
    function parseDate(dateString) {
      const [day, month, year] = dateString.split("-").map(Number);
      return new Date(year, month - 1, day); // Months are zero-indexed
    }

    let maxTrimmingDate = null;
    packingList.forEach((item) => {
      const trimmingDate = parseDate(item.trimmingDate);
      if (!maxTrimmingDate || trimmingDate > maxTrimmingDate) {
        maxTrimmingDate = trimmingDate;
      }
    });
    return maxTrimmingDate.toLocaleDateString("en-US");
  };

  const exportPdf = () => {
    var doc = new jsPDF("p", "mm", [150, 100]);

    QRCode.toDataURL(getBoxNo(), { margin: 1 }).then((lotCode) => {
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text(`Case No`, 5, 10);
      doc.text(getMostProperty("certificateType"), 90, 10);

      doc.setFontSize(12);
      doc.text(getBoxNo(), 37, 10);
      doc.setFontSize(9);
      doc.text(`FROZEN YELLOWFIN TUNA LOIN`, 25, 20);
      doc.text(`(Thunus albacares)`, 35, 24);

      doc.setFontSize(8);
      doc.text(`Grade`, 5, 35);
      doc.text(getMostProperty("grade"), 5, 38);
      doc.text(`Size`, 30, 35);
      doc.text(handleSize(parseInt(getMostProperty("size"))), 30, 38);

      doc.text(`Net Weight`, 55, 35);
      doc.text(Number(totalWeight()).toFixed(2), 55, 38);
      doc.text(`Pieces`, 85, 35);
      doc.text(packingList.length.toString(), 85, 38);

      doc.addImage(lotCode, "JPEG", 45, 42, 50, 50);

      doc.text(`Fleet`, 5, 45);
      doc.text(getMostProperty("fleet"), 5, 48);

      const [month, day, year] = maxPDC().split("/");

      const expireDate = `${day}/${month}/${parseInt(year) + 2}`;
      doc.text(`Expiry Date`, 5, 60);
      doc.text(expireDate, 5, 63);

      const productionDate = `${day}/${month}/${year}`;
      doc.text(`Production Date`, 5, 75);
      doc.text(productionDate, 5, 78);

      doc.text(`Processed By:`, 5, 100);
      doc.text(`CV. MITRA TUNA MANDIRI`, 5, 105);
      doc.text(`Kompleks PPN Ternate`, 5, 108);
      doc.text(`Bastiong Talangame, Ternate`, 5, 111);
      doc.text(`North Maluku, Indonesia`, 5, 114);

      doc.text(`Keep Frozen -18°C`, 5, 125);
      doc.text(`CONTAINS : Fish (Tuna)`, 5, 128);

      doc.autoPrint();
      var iframe = document.createElement("iframe");
      iframe.src = doc.output("datauristring");
      iframe.style.width = "0";
      iframe.style.height = "0";
      document.body.appendChild(iframe);
    });
  };

  const month = new Date(packingDate).getMonth() + 1;
  const year = new Date(packingDate).getFullYear().toString().substring(2);

  const getBoxNo = () => {
    return `MT-${year}${addLeadingZero(month, 2)}.${addLeadingZero(
      maxBox + 1,
      4
    )}`;
  };

  const totalWeight = () => {
    return packingList.reduce((sum, item) => sum + parseFloat(item.weight), 0);
  };

  const getMostProperty = (property) => {
    const propertyCounts = packingList.reduce((counts, item) => {
      counts[item[property]] = (counts[item[property]] || 0) + 1;
      return counts;
    }, {});

    const mostCommonProperty = Object.keys(propertyCounts).reduce((a, b) =>
      propertyCounts[a] > propertyCounts[b] ? a : b
    );

    return mostCommonProperty;
  };

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">{getBoxNo()}</h5>
            <div className="d-flex align-items-center">
              <input
                type="date"
                className="form-control me-3"
                value={packingDate}
                onChange={(e) => setPackingDate(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                Save
              </button>
              {/* <button
                className="btn btn-secondary mx-2"
                onClick={() => {
                  if (packingList.length > 0) {
                    exportPdf();
                  }
                }}
              >
                <i className="bi bi-printer"></i>
              </button> */}
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Internal Lot Code</th>
                <th scope="col">Certificate</th>
                <th scope="col">PDC</th>
                <th scope="col">Grade</th>
                <th scope="col">Size</th>
                <th scope="col">Fleet</th>
                <th scope="col">Weight</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {packingList &&
                packingList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{item.lotCode}</th>
                      <td>{item.certificateType}</td>
                      <td>{item.trimmingDate}</td>
                      <td>{item.grade}</td>
                      <td>{handleSize(item.size)}</td>
                      <td>{item.fleet}</td>
                      <td>{item.weight}</td>
                      <td>
                        <button
                          className="btn btn-danger py-0 px-1"
                          onClick={() => handleDelete(index)}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            {packingList.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={5}>Total</td>
                  <td>{packingList.length} Pcs</td>
                  <td>{Number(totalWeight()).toFixed(2)} Kg</td>
                  <td></td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
