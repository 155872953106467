import React, { useEffect, useState } from "react";
import CSMReceiving from "../../API/CSMReceiving";
import CSMVendor from "../../API/CSMvendor";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Input } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Action } from "../../Constant";
import moment from "moment";

export default function CreateForm({ uuid, setIsOpen }) {
  const { setToastInfo, setIsShowToast, setLastDataModificationTimestamp } =
    useApplicationStoreContext();
  const _CSMReceiving = new CSMReceiving();
  const _CSMVendor = new CSMVendor();

  const [action, setAction] = useState(Action.CREATE);

  const formik = useFormik({
    initialValues: {
      receivingDate: moment(new Date()).format("YYYY-MM-DD"),
      receivingLot: "",
      vendor: "",
      csmReff: "",
    },
    validationSchema: Yup.object({
      receivingDate: Yup.string().required("receiving date is required"),
      vendor: Yup.object().required("vendor is required"),
    }),
    onSubmit: () => {
      if (action === Action.CREATE) {
        const data = {
          ...formik.values,
          vendorUuid: formik.values.vendor.value,
        };

        _CSMReceiving
          .CreateNewReceiving(data)
          .then((response) => {
            setToastInfo({
              message: "Receiving successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen(false);
            setLastDataModificationTimestamp(new Date());
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Receiving failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        const data = {
          ...formik.values,
          vendorUuid: formik.values.vendor.value,
        };
        _CSMReceiving
          .UpdateReceiving(uuid, data)
          .then((response) => {
            setToastInfo({
              message: "Receiving successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen(false);
            setLastDataModificationTimestamp(new Date());
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Receiving failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  useEffect(() => {
    if (uuid) {
      setAction(Action.VIEW);
      _CSMReceiving
        .ReadReceivingByUuid(uuid)
        .then((response) => {
          const data = {
            vendor: {
              value: response.data.vendorUuid,
              label: response.data.rmLotCode,
              reff: response.data.reff,
            },
            reff: response.data.reff,
            ...response.data,
          };

          formik.setValues(data);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response?.status === 403
                ? err.response?.data?.message
                : "failed to get server",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  }, [uuid]);

  return (
    <form>
      <Input
        label="RM Lot Code"
        type="select-api"
        name="vendor"
        onChange={(e) => {
          formik.setFieldValue("vendor", e.target.value);
          formik.setFieldValue("csmReff", e.target.value.reff);
        }}
        value={formik.values.vendor}
        errorMessage={formik.errors?.vendor}
        isError={formik.errors.vendor && formik.touched.vendor}
        api={_CSMVendor.PagedSearchVendor}
        handleSetOptions={(pt) => ({
          value: pt.uuid,
          label: pt.rmLotCode,
          reff: pt.reff,
        })}
      />
      <Input
        label="CSM Reff"
        type="text"
        name="csmReff"
        value={formik.values.vendor.reff}
        disabled={true}
      />
      <Input
        label="Date"
        type="date"
        name="receivingDate"
        onChange={formik.handleChange}
        value={formik.values.receivingDate}
        errorMessage={formik.errors?.receivingDate}
        isError={formik.errors.receivingDate && formik.touched.receivingDate}
      />
      <Input
        label="CSM Lot"
        type="text"
        name="receivingLot"
        onChange={formik.handleChange}
        value={formik.values.receivingLot}
        placeholder="Input CSM Lot Code"
        errorMessage={formik.errors?.receivingLot}
        isError={formik.errors.receivingLot && formik.touched.receivingLot}
      />
      <div className="row mb-3">
        <div className=" d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="ms-2 btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {action === Action.CREATE ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
