import { _Delete, _Get, _Patch, _Post } from "./base";
import Cookies from "js-cookie";

const endpoint = "csm/packing";

class CSMReceiving {
  constructor() {}

  GetPacking = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/receiving`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  ReadPackingByMonth = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/monthly`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  ReadPackingYearly = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/yearly`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CreateNewPacking = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      data,
      _options
    );
  };

  ReadPackingDetail = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  DeletePacking = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  DeleteItemPacking = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
      ..._options,
    });
  };

  ExportDailyPacking = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/report`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  UpdatePacking = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      data,
      _options
    );
  };
}

export default CSMReceiving;
