import React, { useEffect, useState } from "react";
import CSMCutting from "../../API/CSMCutting";
import CSMLeftOver from "../../API/CSMLeftOver";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Input } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Action } from "../../Constant";
import moment from "moment";

export default function CreateForm({ uuid, setIsOpen, setRefresh }) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const _csmcutting = new CSMCutting();
  const _csmleftover = new CSMLeftOver();
  const [action, setAction] = useState(Action.CREATE);

  const formik = useFormik({
    initialValues: {
      pt: "",
      pdc: "",
    },
    validationSchema: Yup.object({
      pt: Yup.object().required("PT is required"),
      pdc: Yup.string().required("PDC line 2 is required"),
    }),
    onSubmit: (values) => {
      const data = {
        cuttingUuid: values.pt.value,
        pdc: values.pdc,
        lot: values.lot,
      };

      if (action === Action.CREATE) {
        _csmleftover
          .CreateLeftOver(data)
          .then((response) => {
            setToastInfo({
              message: "Left Over successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen();
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Left Over failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        _csmleftover
          .UpdateLeftOver(uuid, data)
          .then((response) => {
            setToastInfo({
              message: "Left Over successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen();
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Receiving failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  useEffect(() => {
    if (uuid) {
      setAction(Action.EDIT);
      _csmleftover.GetLeftOverDetail(uuid).then((response) => {
        const update = {
          ...response.data,
          pt: { value: response.data.cuttingUuid, label: response.data.pt },
          pdc: moment(response.data.pdc).format("YYYY-MM-DD"),
        };
        formik.setValues(update);
      });
    }
  }, [uuid]);

  return (
    <form>
      <Input
        label="PT"
        type="select-api"
        name="pt"
        onChange={formik.handleChange}
        value={formik.values.pt}
        placeholder="Select PT"
        errorMessage={formik.errors?.pt}
        isError={formik.errors.pt && formik.touched.pt}
        api={_csmcutting.GroupByMonth}
        handleSetOptions={(pt) => ({ value: pt.uuid, label: pt.pt })}
      />
      <Input
        label="PDC Line2"
        type="date"
        name="pdc"
        onChange={formik.handleChange}
        value={formik.values.pdc}
        errorMessage={formik.errors?.pdc}
        isError={formik.errors.pdc && formik.touched.pdc}
      />
      <div className="row mb-3">
        <div className=" d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen();
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="ms-2 btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {action === Action.CREATE ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
