import { _Get, _Post, _Patch, _Delete } from "./base";
import Cookies from "js-cookie";

const endpoint = "mtm/receiving";

const ReadReceivingByMonth = (params) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/search`, {
    params: {
      ...params,
    },
    ..._options,
  });
};

const CreateNewReceiving = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Post(`${process.env.REACT_APP_API_URL}/${endpoint}`, data, _options);
};

const ReadReceivingByUuid = (uuid) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail/${uuid}`, {
    ..._options,
  });
};

const UpdateReceiving = (uuid, data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Patch(
    `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
    data,
    _options
  );
};

const DeleteReceiving = (uuid) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Delete(
    `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
    _options
  );
};

const ReadReceivingByVendor = (pageIndex, pageSize, year, month) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/year`, {
    params: {
      pageIndex,
      pageSize,
      year,
      month,
    },
    ..._options,
  });
};

const ReadReceivingByReceivingDate = (
  pageIndex,
  pageSize,
  year,
  month,
  uuid
) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/daily`, {
    params: {
      pageIndex,
      pageSize,
      year,
      month,
      uuid,
    },
    ..._options,
  });
};

const ReadReceivingByDate = (vendorUuid, receivingDate) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/find`, {
    params: {
      vendorUuid,
      receivingDate,
    },
    ..._options,
  });
};

const ExportDailyReceiving = (date) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/dailyreport`, {
    params: {
      ...date,
    },
    ..._options,
  });
};

const ReadLastSequenceReceiving = (date, uuid) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/sequence/${date}/${uuid}`,
    {
      ..._options,
    }
  );
};

export {
  CreateNewReceiving,
  ReadReceivingByUuid,
  UpdateReceiving,
  DeleteReceiving,
  ReadReceivingByMonth,
  ReadReceivingByDate,
  ReadReceivingByVendor,
  ReadReceivingByReceivingDate,
  ExportDailyReceiving,
  ReadLastSequenceReceiving,
};
