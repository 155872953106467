import React from "react";
import CuttingModel from "../../API/CSMCutting";
import { DataTable } from "../../Components";
import fileDownload from "js-file-download";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import moment from "moment";

export default function DetailDate({
  selectedPT,
  setSelectedPT,
  setSelectedDate,
  selectedDate,
}) {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const _cutting = new CuttingModel();

  const tableHeader = [
    {
      name: "Cutting Date",
    },
    {
      name: "Total Count",
    },
    {
      name: "Total Weight",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "cuttingDate",
      view: (data) => {
        return (
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setSelectedDate(moment(data.cuttingDate).format("DD-MM-YYYY"));
            }}
          >
            {moment(data.cuttingDate).format("DD-MM-YYYY")}
          </a>
        );
      },
    },
    {
      name: "totalCount",
    },
    {
      view: (data) => parseFloat(data.totalWeight).toFixed(2),
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-info py-0 px-1 text-white"
              onClick={() => {
                handleExportDaily(data);
              }}
            >
              <i className="bi bi-download"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleExportDaily = (data) => {
    const cuttingDate = moment(data.cuttingDate).format("YYYY-MM-DD");
    if (selectedPT.vendor == "ID") {
      _cutting
        .ExportCuttingPTIndo(selectedPT.uuid, {
          cuttingDate,
        })
        .then((response) => {
          const fileName = `PT - ${selectedPT.pt} - ${cuttingDate}.csv`;
          fileDownload(response.data, fileName);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "data not found",
            background: "danger",
          });
          setIsShowToast(true);
        });
    } else {
      _cutting
        .ExportCuttingPTCSM(selectedPT.uuid, { cuttingDate })
        .then((response) => {
          const fileName = `PT - ${selectedPT.pt} - ${cuttingDate}.csv`;
          fileDownload(response.data, fileName);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "data not found",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  };

  if (!selectedPT) {
    return null;
  }

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Detail PT {selectedPT.pt}</h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedPT();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>
          <DataTable
            api={
              selectedPT.vendor == "ID"
                ? _cutting.CuttingDetailGroupByCuttingDateIndo
                : _cutting.CuttingDetailGroupByCuttingDate
            }
            params={{ uuid: selectedPT.uuid }}
            tableHeader={tableHeader}
            tableBody={tableBody}
            dependencies={[selectedPT.uuid]}
            activeClassName={(item) => {
              if (
                moment(item?.cuttingDate).format("DD-MM-YYYY") === selectedDate
              ) {
                return "active";
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
