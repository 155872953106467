import React, { useState } from "react";
import Create from "./Create";
import ShipmentTable from "./ShipmentTable";
import Detail from "./Detail";
import { ModalPopUp } from "../../Components";

export default function Index() {
  const today = new Date();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();

  const [date, setDate] = useState(`${yyyy}-${mm}`);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState();
  const [editShipment, setEditShipment] = useState();
  const [isReload, setIsReload] = useState(false);
  const newDate = date?.split("-");
  const [year, month] = newDate;

  const component = () => {
    return (
      <Create
        setIsShowModal={setIsOpenModal}
        editShipment={editShipment}
        setIsReload={setIsReload}
      />
    );
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Shipment</h1>
        <div className="d-flex justify-content-between align-items-end mb-5">
          <div className="d-flex col-md-3 mt-3">
            <input
              type="month"
              id="monthInput"
              name="monthInput"
              min="yyyy-MM"
              max="yyyy-MM"
              value={date}
              className="form-control"
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          <button
            className="btn btn-primary me-1"
            onClick={() => {
              setEditShipment();
              setIsOpenModal((prev) => !prev);
            }}
          >
            Create
          </button>
        </div>
        {date && (
          <ShipmentTable
            date={date}
            year={year}
            month={month}
            setSelectedShipment={setSelectedShipment}
            setEditShipment={setEditShipment}
            setIsOpenModal={setIsOpenModal}
            isReload={isReload}
          />
        )}
        {selectedShipment && <Detail selectedShipment={selectedShipment} />}
      </div>
      <ModalPopUp
        component={component}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
      />
    </main>
  );
}
