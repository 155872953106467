import React, { useState } from "react";
import CSMShipment from "../../API/CSMShipment";
import { ModalPopUp, DataTable, ConfirmationModal } from "../../Components";
import CreateForm from "./CreateForm";
import fileDownload from "js-file-download";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import moment from "moment";
import { useModal } from "../../Hook/useModal";
import Plan from "./Plan";

export default function MonthlyTable({ setSelectedDate, selectedDate }) {
  const _csmshipment = new CSMShipment();
  const { lastDataModificationTimestamp, setToastInfo, setIsShowToast } =
    useApplicationStoreContext();
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState();
  const [isPlan, setPlan] = useState(false);

  const createModal = useModal();
  const confirmationModal = useModal();

  const component = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create Shipment</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={createModal.toggleModal}
          />
        </div>
        <div className="modal-body">
          <CreateForm
            setIsOpen={createModal.toggleModal}
            uuid={selected?.uuid}
            setRefresh={setRefresh}
          />
        </div>
      </div>
    );
  };

  const handleExportDaily = (data) => {
    _csmshipment
      .ShipmentReport({ shipmentUuid: data.uuid })
      .then((response) => {
        const fileName = `shipment ${data.po} .csv`;
        fileDownload(response.data, fileName);
      })
      .catch((err) => {});
  };

  const tableHeader = [
    {
      name: "PO",
    },
    {
      name: "Invoice",
    },
    {
      name: "Destination",
    },
    {
      name: "Loading Date",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "year",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setPlan(false);
              setSelectedDate(data);
            }}
          >
            {data.po}
          </a>
        );
      },
    },
    {
      name: "invoice",
    },
    {
      name: "destination",
    },
    {
      name: "date",
      view: (data) => moment(data.date).format("DD-MM-YYYY"),
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-success py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                setPlan((prev) => !prev);
                setSelectedDate();
              }}
            >
              <i className="bi bi-bar-chart"></i>
            </button>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                createModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
            <button
              className="btn btn-info py-0 px-1 text-white"
              onClick={() => {
                handleExportDaily(data);
              }}
            >
              <i className="bi bi-download"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    _csmshipment
      .DeleteShipment(selected.uuid)
      .then(() => {
        setToastInfo({
          message: "packing successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch(() => {
        setToastInfo({
          message: "packing failed to delet",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Shipment</h5>
            <div className="align-items-center d-flex justify-content-between">
              <div className="d-flex">
                <button
                  className="btn btn-primary me-1"
                  onClick={createModal.toggleModal}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
          <DataTable
            api={_csmshipment.PagedSearchShipment}
            pageSize={10}
            tableHeader={tableHeader}
            tableBody={tableBody}
            dependencies={[lastDataModificationTimestamp, refresh]}
            params={{ keyword: "" }}
            activeClassName={(item) => {
              if (item?.po === selectedDate?.po) {
                return "active";
              }
            }}
          />
        </div>
      </div>
      {isPlan && <Plan setPlan={setPlan} uuid={selected?.uuid} />}
      <ModalPopUp
        component={component}
        isOpen={createModal.modal}
        setIsOpen={createModal.toggleModal}
        setRefresh={setRefresh}
      />
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        onSubmit={handleDelete}
        message={"Are you sure delete this item?"}
      />
    </div>
  );
}
