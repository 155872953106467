import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState("");
  const inventoryRef = useRef();

  useEffect(() => {
    setActive(location.pathname.substring(1));
  }, [location]);

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          <a
            className={`nav-link ${active === "dashboard" ? "" : "collapsed"}`}
            href="#"
          >
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${active === "plant" ? "" : "collapsed"}`}
            data-bs-target="#components-nav"
            data-bs-toggle="collapse"
            href="/plant"
            onClick={(e) => {
              e.preventDefault();
              navigate("/plant");
            }}
          >
            <i className="bi bi-building-gear"></i>
            <span>Plant</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${active === "vendor" ? "" : "collapsed"}`}
            data-bs-target="#components-nav"
            data-bs-toggle="collapse"
            href="/vendor"
            onClick={(e) => {
              e.preventDefault();
              navigate("/vendor");
            }}
          >
            <i className="bi bi-people"></i>
            <span>Vendor</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${active === "receiving" ? "" : "collapsed"}`}
            data-bs-target="#components-nav"
            data-bs-toggle="collapse"
            href="/receiving"
            onClick={(e) => {
              e.preventDefault();
              navigate("/receiving");
            }}
          >
            <i className="bi bi-receipt"></i>
            <span>Receiving</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${active === "trimming" ? "" : "collapsed"}`}
            data-bs-target="#forms-nav"
            data-bs-toggle="collapse"
            href="/trimming"
            onClick={(e) => {
              e.preventDefault();
              navigate("/trimming");
            }}
          >
            <i className="bi bi-diagram-3"></i>
            <span>Trimming</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${active === "retouching" ? "" : "collapsed"}`}
            data-bs-target="#tables-nav"
            data-bs-toggle="collapse"
            href="/retouching"
            onClick={(e) => {
              e.preventDefault();
              navigate("/retouching");
            }}
          >
            <i className="bi bi-droplet"></i>
            <span>Retouching</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${active === "packing" ? "" : "collapsed"}`}
            data-bs-target="#charts-nav"
            data-bs-toggle="collapse"
            href="/packing"
            onClick={(e) => {
              e.preventDefault();
              navigate("/packing");
            }}
          >
            <i className="bi bi-box-seam"></i>
            <span>Packing</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${active === "cutting" ? "" : "collapsed"}`}
            data-bs-target="#icons-nav"
            data-bs-toggle="collapse"
            href="/cutting"
            onClick={(e) => {
              e.preventDefault();
              navigate("/cutting");
            }}
          >
            <i className="bi bi-truck"></i>
            <span>Cutting</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${active === "packing2" ? "" : "collapsed"}`}
            data-bs-target="#icons-nav"
            data-bs-toggle="collapse"
            href="/packing2"
            onClick={(e) => {
              e.preventDefault();
              navigate("/packing2");
            }}
          >
            <i className="bi bi-truck"></i>
            <span>Packing Line 2</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${active === "repack" ? "" : "collapsed"}`}
            data-bs-target="#charts-nav"
            data-bs-toggle="collapse"
            href="/repack"
            onClick={(e) => {
              e.preventDefault();
              navigate("/repack");
            }}
          >
            <i className="bi bi-box-seam"></i>
            <span>Repack</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${active === "leftover" ? "" : "collapsed"}`}
            data-bs-target="#icons-nav"
            data-bs-toggle="collapse"
            href="/leftover"
            onClick={(e) => {
              e.preventDefault();
              navigate("/leftover");
            }}
          >
            <i className="bi bi-truck"></i>
            <span>Left Over</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${active === "shipment" ? "" : "collapsed"}`}
            data-bs-target="#icons-nav"
            data-bs-toggle="collapse"
            href="/shipment"
            onClick={(e) => {
              e.preventDefault();
              navigate("/shipment");
            }}
          >
            <i className="bi bi-truck"></i>
            <span>Shipment</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className={`nav-link ${
              active?.includes("inventory") ? "" : "collapsed"
            }`}
            data-bs-target="#charts-nav"
            data-bs-toggle="collapse"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const isExistClass =
                inventoryRef.current.classList.contains("show");

              if (isExistClass) {
                inventoryRef.current.classList.remove("show");
                return;
              }

              inventoryRef.current.classList.add("show");
            }}
          >
            <i className="bi bi-boxes"></i>
            <span>Inventory</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul
            id="forms-nav"
            className={`nav-content collapse ${
              active === "inventory" ? "" : undefined
            }`}
            data-bs-parent="#sidebar-nav"
            ref={inventoryRef}
          >
            <li>
              <a
                href="inventory/chiller"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/inventory/chiller");
                }}
                className={active?.includes("chiller") ? "active" : undefined}
              >
                <i className="bi bi-circle"></i>
                <span>Chiller</span>
              </a>
            </li>
            <li>
              <a
                href="inventory/abf"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/inventory/abf");
                }}
                className={active?.includes("abf") ? "active" : undefined}
              >
                <i className="bi bi-circle"></i>
                <span>ABF</span>
              </a>
            </li>
            <li>
              <a
                href="inventory/cs"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/inventory/cs");
                }}
                className={
                  active?.includes("inventory/cs") ? "active" : undefined
                }
              >
                <i className="bi bi-circle"></i>
                <span>CS</span>
              </a>
            </li>
            <li>
              <a
                href="inventory/indo"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/inventory/indo");
                }}
                className={
                  active?.includes("inventory/indo") ? "active" : undefined
                }
              >
                <i className="bi bi-circle"></i>
                <span>CS Indo</span>
              </a>
            </li>
            <li>
              <a
                href="inventory/packing2"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/inventory/packing2/csm");
                }}
                className={
                  active?.includes("inventory/packing2/csm")
                    ? "active"
                    : undefined
                }
              >
                <i className="bi bi-circle"></i>
                <span>CS FG</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </aside>
  );
}
