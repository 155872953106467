import React, { useEffect, useState, useRef } from "react";
import {
  GetVendorCode,
  CreateNewReceiving,
  ReadReceivingByUuid,
  UpdateReceiving,
  PagedSearchVendor,
  ReadLastSequenceReceiving,
} from "../../API";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import Select from "react-select";

export default function LoinForm({ uuid, setIsOpen, onUpdate, onCreate }) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const formattedDate = yyyy + "-" + mm + "-" + dd;
  const weightRef = useRef();
  const pcsRef = useRef();
  const initialData = {
    receivingDate: formattedDate,
    pcs: "",
    size: "0",
    grade: "ABC",
    weight: "",
    vendorCode: "",
    vendorUuid: "",
    owner: "",
    remarks: "",
  };

  const initialAction = {
    create: "create",
    view: "view",
  };

  const [data, setData] = useState(initialData);
  const [selectedVendor, setSelectedVendor] = useState();
  const [action, setAction] = useState(initialAction.create);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [vendorOptions, setVendorOption] = useState([]);
  const [sequence, setSequence] = useState(0);

  const getLatestSequence = () => {
    ReadLastSequenceReceiving(data.receivingDate, data.vendorUuid).then(
      (response) => {
        if (response.data.sequence) {
          setSequence(response.data.sequence);
        } else {
          setSequence(0);
        }
      }
    );
  };

  useEffect(() => {
    if (data.receivingDate && data.vendorUuid) {
      getLatestSequence(data);
    }
  }, [data.receivingDate, data.vendorUuid]);

  useEffect(() => {
    if (data.vendorCode && data.vendorCode.length === 5) {
      GetVendorCode(data.vendorCode).then((response) => {
        setSelectedVendor([
          {
            value: response.data.uuid,
            label: response.data.vendorCode,
          },
        ]);
        const ownerList = response.data.owner.split(",");
        const options = ownerList.map((item) => ({ value: item, label: item }));
        setOwnerOptions(options);
      });
    } else {
      setSelectedVendor();
    }
  }, [data.vendorCode]);

  useEffect(() => {
    PagedSearchVendor({ category: "loin" }).then((response) => {
      const options = response.data.map((item) => {
        return { value: item.uuid, label: item.vendorCode };
      });
      setVendorOption(options);
    });
  }, []);

  const handleSubmit = () => {
    if (action === initialAction.create) {
      CreateNewReceiving(data)
        .then(() => {
          setToastInfo({
            message: "Receiving successfully created",
            background: "success",
          });
          setData({ ...data, weight: null, pcs: null });
          pcsRef.current.value = "";
          weightRef.current.value = "";
          setIsShowToast(true);
          onCreate();
          getLatestSequence();
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "Receiving failed created",
            background: "danger",
          });
          setIsShowToast(true);
        });
    } else {
      UpdateReceiving(uuid, data)
        .then((response) => {
          setToastInfo({
            message: "Receiving successfully updated",
            background: "success",
          });
          setIsShowToast(true);
          setIsOpen(false);
          onUpdate();
          getLatestSequence();
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "Receiving failed updated",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  };

  useEffect(() => {
    if (uuid) {
      setAction(initialAction.view);
      ReadReceivingByUuid(uuid)
        .then((response) => {
          setData(response.data.data);
          if (response.data.data.owner) {
            const ownerList = response.data.data.owner.split(",");
            const options = ownerList.map((item) => ({
              value: item,
              label: item,
            }));
            setSelectedOwner(options);
          }
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "failed to get server",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  }, [uuid]);

  return (
    <form>
      <div className="row mb-3">
        <div className="col-md-6">
          <label className="form-label">Last Sequence</label>
          <input className="form-control" disabled value={sequence} />
        </div>
        <div className="col-md-6">
          <label className="form-label">Next Sequence</label>
          <input className="form-control" value={sequence + 1} disabled />
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">
          Vendor
        </label>
        <div className="col-sm-10">
          <Select
            value={selectedVendor}
            name="vendors"
            options={vendorOptions}
            className="basic-multi-select"
            classNamePrefix="select owner"
            onChange={(e) => {
              setSelectedOwner([]);
              setData({
                ...data,
                vendorCode: e.label,
                vendorUuid: e.value,
              });
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputText" className="col-sm-2 col-form-label">
          Date
        </label>
        <div className="col-sm-10">
          <input
            type="date"
            className="form-control"
            onChange={(e) => {
              setData({ ...data, receivingDate: e.target.value });
            }}
            value={data.receivingDate}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">
          Weight (Kg)
        </label>
        <div className="col-sm-10">
          <input
            ref={weightRef}
            type="number"
            className="form-control"
            value={data.weight}
            onChange={(e) => {
              setData({ ...data, weight: e.target.value });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="size" className="col-sm-2 col-form-label">
          Pcs
        </label>
        <div className="col-sm-10">
          <input
            ref={pcsRef}
            type="number"
            className="form-control"
            list="vendors"
            onChange={(e) => {
              if (e.target.value.length > 2) {
                return;
              }
              setData({ ...data, pcs: e.target.value });
            }}
            value={data.pcs}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          />
        </div>
      </div>

      {data.vendorUuid && (
        <>
          <div className="row mb-3">
            <label htmlFor="inputNumber" className="col-sm-2 col-form-label">
              Owner
            </label>
            <div className="col-sm-10">
              <Select
                value={selectedOwner}
                isMulti
                name="colors"
                options={ownerOptions}
                className="basic-multi-select"
                classNamePrefix="select owner"
                onChange={(r) => {
                  const list = r.map((item) => item.value);
                  setData({ ...data, owner: list });
                  setSelectedOwner(r);
                }}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="inputNumber" className="col-sm-2 col-form-label">
              Remarks
            </label>
            <div className="col-sm-10">
              <textarea
                className="form-control"
                onChange={(e) => setData({ ...data, remarks: e.target.value })}
                value={data.remarks}
              />
            </div>
          </div>
        </>
      )}
      <div className="row mb-3">
        <div className=" d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="ms-2 btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {action === initialAction.create ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
